// css
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

// bs components
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';

// libs
import React, { useState } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

// assets
import contractAbi from './contractAbi.json';

// constants
const contractAddress = '0x4F85620bEB2C229e34D00B1B1C9F5e76Bc212a76';
const tokenPrice = 0.07;
const presaleLive = false;
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '0c5cd9afe89241928942edfb84578bb9'
    }
  }
};

// web3 obj inst
window.web3Modal = new Web3Modal({
  network: 'mainnet',
  cacheProvider: true,
  providerOptions
});


function App() {
  // hooks
  const [account, setAccount] = useState('Connect Wallet');
  const [total, setTotal] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [proof, setProof] = useState('');
  const [loading, setLoading] = useState(false);

  // funcs
  let connectWallet = async () => {
    window.provider = await window.web3Modal.connect();
    window.web3 = new Web3(window.provider);

    window.accounts = await window.web3.eth.getAccounts();
    setAccount(
      String(window.accounts[0]).substring(0, 6) +
      '...' +
      String(window.accounts[0]).substring(38)
    );

    window.contract = new window.web3.eth.Contract(contractAbi, contractAddress);

    const supply = await window.contract.methods.totalSupply().call();
    setTotalSupply(supply);

    let whitelist = [
      "0x0e6C85f2469d3a33Fb1B6bD0811F1b9331570925",
      "0x1a60E2da2b7E9f36782c2eCC2b8f3bbd2308b6fC",
      "0x43252Ad62D5F70C1bdd2aC3A8032dfDa5CcB74b6",
      "0x68425Afc2Ec01ED9a90D0016386766de5710b2B7",
      "0x67c0615D25aC6eF9025fB9282127A2c1988E8458",
      "0x93A13363c7Bf173c9f1a7DA95714d6140EB1A288",
      "0x2bE6E1eb58f51C3D17b2e011C9a59Da6F8C40dF9",
      "0x7b669ebD96c29090F12a1Cf9B0974001644B4Ca8",
      "0xC758F9103BDb6B889153E45ea65e103C03891b43",
      "0xC9B3ea1e77B7418686C9dAFa79826De2aB6CBE2E",
      "0x1ebbC7F8E45a014b00eEDD7389Ae8De4e0B8087F",
      "0xD393243124c3C4BB8405a0e4A6787d1aaEf92406",
      "0x6430E712cD03be5f823cc7D5013fA8dbF0f57f55",
      "0xafB1578412ebFbE26bCf0668148679a8B39BF2F2",
      "0x0e07EF52E4dD6bbecD422219697Af3A4CfAD9c18",
      "0x174A5809729227C3767c7B445196Be240fcDEfa4",
      "0xc98bb9b1C752192F8d3Efd7efd56C219d344a5e2",
      "0xC38Bcc14e735Ff74e080Ff42af7C20F930346cc2",
      "0x639a4bF6A780de6515e80F88180A7A1B27B74Cc6",
      "0x0d5cCbb3632ca170595342b4354C56f4EC02471A",
      "0x4CA582cd67Ee223D400c5E8a5A4A207cAb91D9FB",
      "0xa765f0B0f40ceF54fCb7364dce2b39bA667e2443",
      "0x4949ff09e85eC3Ce292233144476C88618aAa24F",
      "0x1f97dc1a67F4726B8b07EfD4a04772De3B5066Da",
      "0x52892ab92d4154c926ae2f3aaBBa452B1F8eb594",
      "0xFAC9967Df3C33Cf3e6A09E0bb7303E5b01B88f61",
      "0x9f4C07a862dfc60323e5b8DC656511502Ad6E0d1",
      "0x31a0017CbBfAc78E5b89D260223B4BEa79D0a7be",
      "0x59Ca581E49F87dA7424F306c6F5D02A1d334f556",
      "0x77221a1DA2f70df09cfc66753fe838a7218907E9",
      "0x48a292012e6cfa8bfE0DEFB56A747d129Fb19df1",
      "0x433B403CD4409cdF1159D5bB46BF1e2266F0E258",
      "0xBd48F9270A8456b382B30ca623275Eb46F35D357",
      "0x4b208E9ab61b00317328A25Dc4dF0d3Fb534D73d",
      "0x4Adb8DBBa47D0C2bFc60D85158920733AD50ef09",
      "0x3b1Cd6cf0f73CC207b7947621e67d502A3E8CEb0",
      "0x42036705f521833C2ba8218A5bd9A9b34B253B7D",
      "0x31806569DCA18EB4e286A49178DC623AA787BF4E",
      "0xe249297bC213602D0E8c518421476853097D5f89",
      "0x3c3B50a55084c9Fd0901E9944d9131EF0633a94c",
      "0xfc2407e65E68f2CB24f92039A2Fba672Aecc3EF9",
      "0x2ce80061dcfE2f30230161DDE8a1Db4c5b29ee80",
      "0x29E85e206AED7b9e4bC54ED282cCf4371516cb20",
      "0xe361022933A81Dc727796828226Ef2754428a547",
      "0x1B316279928300f949E1671CD373bf79541ce9af",
      "0x5522F90159c806dc226bf13437d4984d63A98bBE",
      "0x3492383A1a8B93b28C2B68Ed07Bb90652a0208Ca",
      "0xA6d8f2Fe26914f08362fE08A14eC9DEF5167586b",
      "0x0A00486490a29CB123208ef16F69B65DB53DAA55",
      "0x1C2aC445E23cbdD385A4bFea8116577D014Cb527",
      "0x65c1297542a417693Fa75516d8B1d411a76cf46F",
      "0xbF1fE17e599C5C0cFfDE05F962a04287174F7Fe2",
      "0x73fc291a4740Fd0D2dF9C0aaab689726684ce300",
      "0xC9bdF0145fAe198d06E51c2a5C83cB7880e27bb4",
      "0x5F9f39a2c982E866D6e5F69E5bE0Ad8397933c22",
      "0x6DF4bb79e67D70F031b78aC7a706B2CBA068F9B1",
      "0xa3750e85b3c239b239aE845ea3FA387397CB699e",
      "0xD5a6fBCfABaeA62928D92E2Db34f8dDaAA74e17b",
      "0x5D5D99d023be88cC1abeF89460009CaA6ab5b8a8",
      "0x7D1F764cd8dfD5D9bAdd25cb119607F31D1CbA39",
      "0xD686E3330507b5a0B32be28d26c6d802A5Ec2997",
      "0x640f34E47Cbc4F4E66875598c8F4B0b39d59Ae51",
      "0xb79b7c3E2dF901795160BEe3b28307f1e79737DE",
      "0xCAF0b87EE5eFe7034d1c70476de424117cEE0603",
      "0x0E565B53Fc36cd6a8d8038339d78FDb119F93f43",
      "0xF49d52E5Fe8F8960286805c017a8602eeC0744ED",
      "0x24800D81D5058BA083B838298C558475cc19b46B",
      "0x12099508e5B615D12B4410f64030aa8853367DCd",
      "0xD5e5c5e90de336CFCb9be93C2eCdf98952eB83cD",
      "0x8bd9a4E1895Ae67F0153fF72DA67AdE177051757",
      "0x7d26241C6e52BAc6629447e278EFADa8B58c48e7",
      "0x74Beb9b4591606F7AB461d1994Ba9EE54088930E",
      "0xD536FC7dd9E646FEf417EA644aBc012717796EC7",
      "0x7C31a8c0aAAB42533259f6E2Abec506E027522a0",
      "0x01EA94dF56c9b23e1706B690A5bEb155dCf928d6",
      "0x65201C9FfC1863C809843Bd4Cb9803F55B8Cc0F8",
      "0x2856613f77E88960eB670506ce957056ff33498F",
      "0x457BB3267e1FF48A2C750d3dE36846542151c03B",
      "0x7e1A3611eCc58e52fF9C576268e4F37bC2c80100",
      "0x7F438F4d968d20B12AcF4A1B67313Bbd051Aae3c",
      "0xa980CeF0182E4aF5FB9D9876b6DC1A5a481A6ef2",
      "0x0F3F8167Bb43e5F94A6649f15e61c7CC4E9D162d",
      "0x97C8dCD4E7ee47494bCD7c309Fd2c78AB8d48Dc4",
      "0x5c2e6e920edEA47711F093b9F6b81e51Bb472a0d",
      "0x3eeF03B82C82016FB8c438aAE3B48Fe137A862bd",
      "0x575af3CA5f0B320e1dE0b7Fd46F7Ac83B00266a2",
      "0xB987FA82c10bb42A09B488d712F107B2D650826A",
      "0x2DDaBE698BcfCda6429C75A861625413A4AC4db0",
      "0xFA6d8B5ad59a9E157038BedEE3320f74010dd63a",
      "0xd0F4a2768Ed0BcD01BdB698E76925d6b62481995",
      "0x09BDe6DA1A7521cBbd7A57be9cf77ff1A7A06dc7",
      "0xb0B0854A73E58A4c10373e09bd5e3CD0090632EA",
      "0xC5466Ed841159d2768e28ef29524dd56bd574A58",
      "0x67140d9808b713367915941B9Fb01629Cef7f275",
      "0xcA033531653E3cB027500B26AA3a54a9ACDB2661",
      "0x7340F45B75b3136BD1201941077E7D546BfA2B3F",
      "0xd5C89d2e77Ad9b15e22d72f8402BED1B9d45FfEF",
      "0x3f963B79A02b135cf21Aa4e7d15cb4c25956c3e9",
      "0x6CF2B3403107cD5a185c354b1aCbFDC1b2bb062c",
      "0x7422337d0Eb148BE943874492E871A521A34bF05",
      "0xF11616CBF5805b3C43f8f737366550435799D0f2",
      "0xF6E76a4Ec92eC015574D8E4D389A9D5aBC17ebe0",
      "0xE421552A6b132674424b385589bA1d821350E4D5",
      "0xBC4Ca6C22D67cE3A6e634e687f0314E87E69F24D",
      "0xa2c067894039DC9458e41b46dF422CDDB8D41cDF",
      "0x5e7bA8467dd346f44ED69BBb53B24C5B19243EeF",
      "0xAcC7f4CB5BF81f35275f30aCBdC356AAe3f75424",
      "0x2F422661AA758Fcceb4372d87835a0b2Bd24E09E",
      "0x74E02793c6d103530098D76866f895D4106C64d5",
      "0x20def9A027a1fD1179Ce00c4872ca0844845c4bf",
      "0x45Cb648a47C038F753e56E6e57173Ee432f6E3f9",
      "0x7D8EbEdDCccDBa1b070c455073aF153450C7d697",
      "0xE1B0a90729B1D8D0938B0e12Cba2873ab3d74017",
      "0xD8284d6a3B2a31BC3d5Cf6b4b73024e708BDc4C2",
      "0xf6F7368840B23c2939356280D504BdCcf88fb6Ef",
      "0x15B012ae12A18c3DdF19708f3dd44b8b3Ef103dc",
      "0x8B0CFf42De2E4ACD7E0BD64be44e05C82F6cdEA6",
      "0x0235b6B300d0F06C90ba4Ad413bf524a5b993f2A",
      "0x720685BDfc8b7dd1c9167c89CEa80384cE19e7F7",
      "0x11677F34ae7f34f2fe36081eB01620fd1B75BFDd",
      "0x90eA8959C8033F250FA137657c7643D55340B446",
      "0x095551Fe5237CE1F0bE8016da0aF07DDEEfcCFe5",
      "0x758E60306232855FcbFC79fB9d7C76c19D05003A",
      "0x77de66677C8057F758af34764FcdC674A31991EC",
      "0xBd9779c46Ed0B78A6f01659b95c251fcB56d24FB",
      "0xed7D52c039077e6c33F5f8d89d427a23BF1c19ce",
      "0x7eFE97AB37723A0E4E62C747A85a9B1148C57d53",
      "0x9bac76c059c1576ac81a41Ca227aC06fb9bA1536",
      "0x3468298ee0CFedf230777af2dfe51048eba4AF94",
      "0x4B2d5f9E124fadf25BcaB5618F10d02254c92d8f",
      "0xDF7e8e9008653D09a47a3beabe58836043E9d742",
      "0x4550E11D57280d3B9C8EE266396177D12A5C147E",
      "0x2859DE2a75F9e64694A0B3B62616EA74120337Cc",
      "0x97538A14b373B41AD8FBdFa0479E96CFee82A8a6",
      "0x2095422DB30ea360201E1a26AF8F37d6C8E19954",
      "0x826076bD46B0E3c868c6956cE3629215f69f75B1",
      "0x762A6DEc3D7357faeb6Daa83a788D79b7fAb42a0",
      "0x7d0313348A1E5491DCA3aC4b20a0B0fe3c6cD731",
      "0x4618D46a1b889dDd135f28ae54811fE0Ffe7e6fb",
      "0xb0D7AD3eA3070203802e16493B661b9cb463fAE8",
      "0xD69D1Bf541668c5D0868290a589c115abDBc7934",
      "0xc513De9D72793F288CBaAa48b89Ad6EE088EB5e9",
      "0x0e009DbeB8644B79304b4D661295F027c5C7fA33",
      "0xa0ABbFcb82AE43D3BD04B2458a5DBcFb627975c2",
      "0xDDb3E140CF367BF990aB45b796BF82808fe43c4a",
      "0xd8687DC1C9DBE4bc0ba521807ba4D197D5004626",
      "0xE71eC1fff54Cd9B161936203682235E607a20546",
      "0xa860d9F21ea7900f1f731ACA1933271aa2DEBE2a",
      "0x62626C064775286440EE685BE675846951a08Af1",
      "0x746e59b15EBEAC8FB86F50d528D0B489C2990932",
      "0xEB70b0DBc7a3e7e8fD8cb1C09204fc8B7d6a0fe2",
      "0x4159846Ca60347E0bC6abC617B430576d6F685b3",
      "0xe96E6Ff5BE1DDa6b73bD64fB46Ab1f9176785564",
      "0xBAEB653cBD12F4fe7ec53bfdE7aA9ACA151e0A58",
      "0x4A3F5455C807c3630fC5BF3b9076f57A85f7386F",
      "0x9e6114f8A8129Ccfff2f03880269B58710A1644E",
      "0x84C97D26cBB899AfDC5C48cAE366e973B182F3dA",
      "0x23CfB5a15729B3a4C228475C96B40e9B0D5C97c5",
      "0x50b7Fe1E8943E53659eF976cB246AdA5BA6C45fC",
      "0x064d2877BE1a359b2359E169cd614c651652Ff48",
      "0xAc4347e68120bb1ADC533884F98164a04da66087",
      "0xAEeE4510f0231ae74861Cad7F13040Da3f2eF2fA",
      "0x1d3062D10f7cEA0d868a03392eC3E93eE0352c8D",
      "0x2Dc49bDd15629268087ba04867F67d66B3B6cFd6",
      "0xBe91655Fb2B26479aE66cC3fd6b23dC629D2197d",
      "0x53AaF8fd2Fc1f3E01bfE9b535b048683B7616FAf",
      "0x575De7C41e7BBc5765B93B3f031c391cd8BFa746",
      "0xc9bF36adB52D6b3f545c5293A9543F6A357177bc",
      "0xa8A22d67ce5Cd8a5A1d6f73CBd92458e058AEdc2",
      "0x25c4DF6841211E986d033EBfa985748A8Fa67c5F",
      "0xb988c18408Ea94139BE704fEc1CF9350d2A0B1e1",
      "0xB33f68Fd9E83392F7D2fE729f622DBd2e77F660D",
      "0x6E9BC8c4f0c082cF6C43AECe591e2585E37227b0",
      "0x4A98310687b59b782a070023FCC7DcB458cEfddB",
      "0x7d2Aa0B7ce6343B3246271a47783D179a2596524",
      "0xc27eC6e267524Ea6D9E0551963901bD61f2CAe05",
      "0xdC15EE81683393EcB97415641F66152713fEaF4C",
      "0xa7CA38ac496656E5d5c22B99144bb3B4b0fBB9BC",
      "0x971E07e5eD1cDD0ab5b4C7442b3ae7A25b1C30b4",
      "0x339e2F6BaA1E2d607095ADfC032f1D9161cA34D9",
      "0x90360323D13becB1503FdBe835999b9092cce6fB",
      "0x33F401238A7f5dF6e918e590A7D02fb2D48c8963",
      "0xF6443332e721065d1b67c2E8a87B8E6f98081C42",
      "0xD9495fcc8d1736738E95Dd170bcfa946f1A4af21",
      "0x8249f0d9544C5685cED101fFb73E8D1d843DbB33",
      "0x5EEA1471F6b363705996F9e640F7f324eCD442bf",
      "0x8bf69C9675ABD773f355AeB659d13DC92E144c73",
      "0xd27AB5c6d52730Eeef0E3A48de6bBB545c8eBa36",
      "0xB89d8c1F8562520661f4468E4e6e27E9f42e1D84",
      "0x677eAa62000fEC4e3485c9F110410B1ad9A3BFaB",
      "0x5C6b7AF7c4d40E9515Bb9D1632686bac94a2Cb23",
      "0xA0A69544C172B96285645aF1f29a310Fa4884122",
      "0x02D4F41fbeE2a57F52678b60BB331C49435A8773",
      "0x68713187BD6b5FCEbA4cEcC3706957A1B718F77b",
      "0xd8eBb4d9B250008d70f6c30DA2c61897aDd5DC20",
      "0xc679613f3917EA506Fd656c0110CC0f0F421E265",
      "0x57327F3092aCd1B09a57C43B9F342b45E0572366",
      "0x4E10866cB20a52F8EddAFaD84fD0E77BE449e4e3",
      "0x6477ba8554A6134D7d9b5d347f093A155C10e44C",
      "0x45A97Da622E861d961bAE239c6E41405A512A68d",
      "0x6D1231Be437DD814a6c4e41EBF404B6d95c0e2bc",
      "0x54684e2dbCd14Ecb8CecBC01063B4fc319D30073",
      "0x1837e8bFb5e7Bf32b829CE359f1Fe0A5c71c37bc",
      "0xE4Cb7c46026F7CadF5fcc5A694cFF696e627AD07",
      "0x3cdf8F95Ac394c9DdC8b9d5C2749789Fe62aE737",
      "0x1C3827Fa56D34C8E1a1E0DcC3b03AC33BD0Dc28F",
      "0xDad4Faf02fBCA5e645284C240Ff4d5293937477b",
      "0xe583De106B6C6Acb1421E64aFe4CFd14A53392b6",
      "0xA392398289Cd42226058b486C748c46B19EBD242",
      "0x7f4dE72934dCb0f8bb5F24093F48af61aCfE737E",
      "0x81c94E3c78eC7D60B78B087F477aA9fB28924121",
      "0xa5128afCF18F8D21474eB2D0AD7dE36B9497cE7d",
      "0x4aC537543237fDBd9Aa2fC7b33D6540EEa8269DE",
      "0x52E8De6102d5155839ee2a5B3885C67c08C54B44",
      "0x96641b16665b0005d3085f13DE6d5a114AFeADf6",
      "0x3De65dB19A2E784D4D456F7E85bdCd309FC88E68",
      "0x3Daaa116223687a572fb99f803A601B88042f9dB",
      "0x0F8ac489f9f7b69f58A7BF7Ca02c4148D7810aCD",
      "0x3F430B7c2Fd3906a306b71BD418A421e48092c14",
      "0x36d1EAb94161d7C9e47FdB09adEA3004796aB076",
      "0x8ce8f79f25EaC2d756977862F3Af0588E666b558",
      "0x03ee858D277BcB1d57b9FE7CfeAc13DfaD39aD0c",
      "0xf2f929d27Ba1293c2a08F8f74beCDcc526Fd0b2d",
      "0x6DB3dA23D441aB61C1A136Cb92978A6603bA7475",
      "0xd208425FB6A952115A4F940BBA51c21Ce165571d",
      "0x817F56ec58C98C11AA50C586d93ceD031A8F8661",
      "0x65059Fbe2aA83e86B330601744521EA28f241F45",
      "0x9DBC1A619Fe67F631269772B20dF9A98a9F35221",
      "0xE2d2Ae7DCC5f123941fe9BADa075c7Ca190D5094",
      "0x7A1F0ae1E092DbD4bb0dA7389BC8e381d054eB29",
      "0xee5c28652e7Dc945e173DDD35dAa173e530b7C8F",
      "0x1dFce4533988c763ec86211472319eB47caEc2aE",
      "0xAA1B2253E6a2acC9D5f56f2B2CC490bcd0feC798",
      "0xB618D796bD7eD78387C7dE0e08BB9868e98fcDCb",
      "0x199D6FcaEd98a0b53825FE388Ab8b5bd843Ed1b8",
      "0x25b3Af160d48759600755Fb0bedAC993BA3Bc3C9",
      "0xf5F354F6733dfd6DaA7FF339993567D87A83C3a9",
      "0x3CFb0A03363b8963455D8B53366c58Da7b56D57C",
      "0xe0B365d695620bF14aAEB1625CA8bae7Dfb3A441",
      "0x6941A1Cc466627d70C77e086817Eab1D22D11A4b",
      "0xd5dc286db39dEA5F10f78b5AFb1Dce45880FEdB2",
      "0x512E98fCBdd4Ed943ee9C21d790834E7193BFFf1",
      "0x1A86401605B120994D9C292b17F2bbB2e800C55a",
      "0xcaC006Aa88838f0719d47cDeB7421D89889415BB",
      "0x570fC7Ab96dFB2B5757c168bB19Fe7BF15a2FD3f",
      "0x317085162ECa4B00B380079eA5C5DF150dBcEbed",
      "0x684c8973F9468D3B79233aE0f0FcaB8F5b718933",
      "0xbcc13427B6E978b03f4E444893496b260A2347A3",
      "0x656AE136a4D4C9F90C937f362B151817368d8fD8",
      "0xCAb1048c0eE008bA869894Cb5d822EE73d72C070",
      "0x09d4685b09992A8663952df63E40d42b75C7152a",
      "0xdF06CcBd92896F74cef0CC3D343eE44696CBaF94",
      "0x525CdC042Af0E2B2317B6D14C4761EF2caDdE71E",
      "0x31Db82096f0DBE81A405AA633112875Fd32Be86d",
      "0x7B2B9281Cc4826ab5B0a1C195e5aE745d3298349",
      "0x08Fc79807B5F6661c4904F51afaC7dB62653ED15",
      "0xD42FB25a7a1c0c3C1640308a63c81c6062f4B435",
      "0x966e3312906DAae0C7b1d98b33F64A4995C0f9d9",
      "0x3Db48001B8Ac12A0994130B4f4BfFB6d864FC827",
      "0xE2B5180fF74cd00a64E2930132483B777361a735",
      "0x8bc70273843afc5F90BEa26BF6CB6b5c21A4750D",
      "0xc53e2a2a129C409Fb3726976fCFc289106c0BFA7",
      "0x49eB4894dBDc6eDf58A562f66342C5AA078697F5",
      "0x558f219f124c88aAd267803D046Cc71AF80A48dE",
      "0x74695eee37968c74BEE92389FB9C0839CABb2C60",
      "0x7c186A88e1A35df60f254B6820fa5B31378A004a",
      "0x378e5829324f4617cdc367025737C28A05d67866",
      "0x364b4898c107aE558D5785bB8f4193a021082c6d",
      "0x2c352C908Ae3E36b585A163E167102C43c312642",
      "0xA23C57fbe89415068609F5a4Ef48ec506559a635",
      "0x4DdE97b62394b8089A1667000ac5f50Dffc1D8E6",
      "0xC88d38DFbd141694d54cfDa41A98ba05Ef9e4C02",
      "0x1Ef53Ef91be9793AF16d9FE84B866e6982B731Cb",
      "0xAD25c0C825287C72cA7F642043bE595694e90803",
      "0xda19Ce1C29d1AD1FD058D6a213d610F445Eb201f",
      "0xE28Ca201150829F877420dcE5713aC8794F7c660",
      "0xde3dad98701720C51463296c4d91923f98A3F5C9",
      "0x5E747865E142bd3fc4f92F2C4de35CC2cB788b54",
      "0xA5eEF25884E7E3c886a41c90044049D2127a1f0d",
      "0xC1Fc6Db13cFa5c1A396b445E7E665603a9816e99",
      "0xc0670E56792Cc704A608EC0bB33BdbC945Aa8e69",
      "0x3a8B4C2133B91133f3BC14D3014327123DDBBc2B",
      "0x51E75704f5DDf05C2e0eCF71Adbb28930c9E041F",
      "0x94662F3f6E2d0055D2765bf372a885f7E1D6484d",
      "0xf4676E7710eef7D1455F6Df4435136A01C6fb95F",
      "0x2CB807Af743103747e172DB66834534b1236CD37",
      "0x83f28286e04D8272350172cabd059A02EC069AA2",
      "0x40366189326186D780a2F880BB79E7690Db6c4f4",
      "0x06798557f4d044844b63BFE10B2E8237943977EF",
      "0x65Fa423F5677243E4af0E0AeE180fb8D6bB28434",
      "0x26950B7334335617303796c67AF322a60B0D9756",
      "0x4A88C85753eeDb498D7aD6A1FCcC0645D3C9Cd09",
      "0xd99cDC24ab3CE90ebe7b032AD3C7eAb021062afb",
      "0x6529549db5CeED9F0d2784887eAe8D7cdD7ddd76",
      "0xE3600c4a0949dC26597915Fc38F04c445B0D6EF9",
      "0x5d6C2887b3741ADfB5AD59B9C4f9bD4e7521e684",
      "0x4cf2AF39AEB3cFE4565955F59D2a6966Cd6d5F68",
      "0x1585594f68C6c60dA3E1c8B6df761bb13678e809",
      "0x31ecc903a6676A5d6ed537872bf470Ff1D3C3d0f",
      "0xBDeDaF3CDbc0e7EA7ed617a480b5b376C7303cce",
      "0x54ad9d40414eD047067ae04C6faFc199A5bb90bB",
      "0x573C367af7B4bc92E3a439C87f2817700CEa0e4a",
      "0xF5cD18059438f1040bEb2F81939719138d665a45",
      "0x20Ff5610eD1A65c2be4fD9287cbE68965BC6a578",
      "0x8C2A278346A5F06eA61fc11b4f025e474fcD8481",
      "0x4343E7B916e42dcB2497a7352E4C71483Dc04Ab6",
      "0xA75931E2612a02bc3D49A47158e3ed06518e80f6",
      "0x2b1b3E332E65915a795202f848CA84a37ec7f471",
      "0x19defcCA777d9378fce0A42a1E00895f539d80B6",
      "0xb7304109aAcCEd5d13c463411C87B0Ab98E27245",
      "0xDCBFc3B3C420731C4f41A2F4B3D005f8C77ff855",
      "0xF2cf6ee4C97Ba3B8774F08611b342AAaF8284396",
      "0x2Dd409F23b1a9bae7bfF80B3791ee22854f17ef7",
      "0x49AF7b835e09C7b3327582E4233Ac91815192617",
      "0x026273Dd2AE6119c0aad379bd6FCDBDB42cB56d2",
      "0x245F4Cbd17Fe0342F1EB7C4b2833f813452af025",
      "0x0cB2a65F75361Be10038C1d55203d0d74220126c",
      "0x8C6Dd30B544477eA7Fa08D766d87424b3d86e742",
      "0xCEB887f4DC5B5d0135c4b3A8AAeE47B7Bf8Ed3E3",
      "0x44354e8C4179c2500A7b919e00Af9323927D68eF",
      "0x8969b320b33Cf9920f4B4031f85B6F817dAc8cEa",
      "0xB137bdbB4d44b4590e727E898F1F0A57C84B446d",
      "0x694b1850132eA69579e6D5625705CbD5C58Ac255",
      "0x0564d44a60DA312B136b2180642CCE4c850dEDe0",
      "0x4a026E21c311a9C873eb25d8dCDe8722Df0a68f9",
      "0x76bB113034bFB268A2ECD77c3d14FE953F8365b9",
      "0x2D538681b53C1aeD149B3565F7fb7A973afFACc7",
      "0x36Cac6104f66b7cDE025560D9DEb948608E8529B",
      "0x1629140FFE0DEf050Ea61B36A72f76c2ee2caCf4",
      "0x3f4804330a0d720463cf9932656ae14E7116FC12",
      "0xf437d55E0BE38379735e16C43da6b29ea2100Bb1",
      "0x4fbCCe3E87643E9E8a86e059CB7f5D424d5e71f5",
      "0x80C6738D7Abaf85930a1671630cF1048ec9078d3",
      "0x7e6c7a2f47A5c26D947bb5e26ad0aaB30cE16bb2",
      "0xB7Ec6Ec5421eb0068008770ad7CD5688293eb194",
      "0x992Cd4e7979b0Dd9b41c40231Fef0fa2eEedb8C0",
      "0x4577107dc9dF602976aBcab6d95E66C7920D599C",
      "0x518Cd50CD5F466a0f958F4adbf60c21c394Ace42",
      "0xc5130f3A0357BC935955c26F1942730D12597e6A",
      "0x88b2ed9Cc97d61fBcAD4221fe310aa2D75040f9D",
      "0xc4a36BA293283BD2bCb31decFf2fe6D98106D615",
      "0xB95e6A3cA1A3350926D247538380AFDf10Fe0A11",
      "0x39BB1eaA9F77F524a638c7De9DaC0da7A4559591",
      "0x62cA89025B911CA35b6ca23b3d98c686948bC4d5",
      "0xD07D42f1323b1f0f141194fd0BD947E5e9196158",
      "0xFcB2D6683DB8246ef13435947B0443adA33Fe055",
      "0x6514297f974CD5142662BbCE514Dd33BB36Bc124",
      "0x6937371084dDc9E1afd5dB34c4a9cd6468472c1C",
      "0x77c4eb201D0118d23Bca042eb475f0dbF8aD013C",
      "0x7Dff71aC6Ec95FF2D33531896e5E1E0838c269Ea",
      "0xAdADFE729079b25CEB0D8E2AA579E2a334F6EaB9",
      "0x92E9f0c4111Bc9A17B19ec820CB8ed1F9157Eb51",
      "0xf73EDd8cfc90264273Bc318e78eC657dC266891f",
      "0x9772cCB2243704f92ca77A281C3280bf7B00C65c",
      "0xd9eaEeB944750661D67194b3d0CD60259A3B4813",
      "0xdBB7c09D6a0A9Fe6471e5c1d9Ea2ef571711B459",
      "0x7Ac20573CBd96873b3497915e38DB0F9F4a15a02",
      "0xE6eCd53d8809adc197F022aC9c9F533A345F7CbB",
      "0x24883f159e17e5B8F997fd575389BBc4250f548E",
      "0xC3146CF363B106E74B9fcC59b74186CdD3acDF5d",
      "0x0664a79acBbFB849A139290c1529275A6d1A9D77",
      "0x5AB246615C8529b05649c6c534066C1c218670C6",
      "0x7546f2D601dc8e09A9613bd0F2e0B2c49037b94e",
      "0xbD3d411293C15f6E4A6bdB8a7f02324c4ac5C5B4",
      "0xe4404f53664cA1Ff7d16a959846Bb5C5e930566E",
      "0x62BE282Dc6aF7F72768dc3058707C57C006A0502",
      "0x838f1d7213D729007822FF192a507B1D3Ee5b478",
      "0xCA60f7d2AFaDe24e99d93bC5841bF5E771a4272f",
      "0xD03Ad7C9d73FB5Ffb9E04acCB8686104e6E02d8C",
      "0x6Afe6Aae767436122e2Bbbc55a124189F1466E04",
      "0x0f5945629909e3E07baaCc65bBbFb2588C1700dB",
      "0xb6d769dbDfd5308BEee50fb09cA2Da99a3b75385",
      "0x6C7C2FCDEeAB740A97A045A2880c34175208B195",
      "0x36f1C442CD589f5d45dbb287b85b40db33eDAC76",
      "0xb5d4A8F40Cc2d8Cf6205a871cE54C452680CE13F",
      "0xd5F1901cD625D68cE35AFd389eBc4E05AeFf3aA0",
      "0x91C2b80e1bba510F55A2a29d39938996158981ef",
      "0xd72a1295989C0093e1fAcb2aB4E9cCE1d6Bb1b6f",
      "0x7b7E78dc9Ba5725f4f8159F84A8C49B98a1070Fe",
      "0x5F1E205CfCae123e8a56c244bF6f4194aCb16339",
      "0x239C87411d36d1546d4827C2a6D29cf6a1EBd391",
      "0x7268Dc78BE2F1dd90c8076D3293E3aB123fe0425",
      "0x90B682e4C468e1e2e506eE07D1e31ce8A1870856",
      "0xB560761a8d8024762C5612095673946a53c6F390",
      "0xB60a8Ca4dbEBCF07Fa603976E62037dcfbBb1630",
      "0x2EAc72F0eccB2006d434a6F290440760D514dfB9",
      "0x73A78E335c8b252d98b29805F41F825CE26a9e03",
      "0x8a204271Fe9C21cDb4f712d2A642586E0584AF92",
      "0x8760dCF0F9858eAC43Aa04C7EA3BDbc158A3aDEE",
      "0xFc1E1fdAa188274A9ebAf44647aa9973c7eDa882",
      "0x100d301DF570bAd7821F37FC20b2193f583E3443",
      "0x98e0c730d462214cbFAa48cB9D022E1FD5B33BCD",
      "0x32A1CF3e52A0609493D3eF95311D5A4B4f80a89d",
      "0x2588f3AC4db4e97483296948010c9A30B21CA55b",
      "0x8788733Ed45279d5bB595d33b4Be011C8fb40130",
      "0x733F7cf0eea0C9136e8ADDA22beFbD377fB4C7a4",
      "0xcf8282C319E5dEa409f059c1Cb0d51EFBc231624",
      "0x71230754fff425Ab498B4F3713DdbBBF76459bE4",
      "0xf3D760544eeC5Ea4784e6591746ada6618694e83",
      "0xd4a21e1405D6D1b95730F092E98EF75c2Dd59c7b",
      "0x4949eadd6781D25a822e984f616d63CA061351a1",
      "0x1939F06D3179078C74443f778e9ac72589eeee79",
      "0x69925d1D315BEBcD8a7F6d7612b4a15d407DcF21",
      "0xe870BA83582F281CA029f8e9fF2F7334572330Fc",
      "0x847BEA17C856Eb4E7A86b032A6fEc0b8Be1fD2F3",
      "0xFA5C9E28092cfd2De06B171cC93e0D03b4B85039",
      "0x4d3c1a6f231B196b330eeF14C6913e17402891a3",
      "0x7fDdCFF52787134f451F3D32F09AB2539B8a7262",
      "0x93A860f050a69DcbC766935911e6A8Dc0F20cb47",
      "0xD5003fAba345042A7F7Ae6FE0031351214A3D094",
      "0x647cC9A8bA1dc273d346fe7AD3C9410b6A22C5aC",
      "0xB4096243bC78244956e6029EF25e4331eF7587b6",
      "0xF1a67B8624172dE458b1c39B4593C53FC9e2896e",
      "0x682F21A52406bC22Be8930005A53B6001E76Ad4d",
      "0xe47359d56E47E1bBB3404c04116C9105456805ED",
      "0x3C2728b5C506639A68C8Ab152D2C201D67030899",
      "0x94c0a3dfF04A113E221E80c16acEa1F39A6EDD6C",
      "0x7536d84B741AF6297Be0ddb107390Dbe476056d8",
      "0x3977e1D80FfF1288B8f12de97Ac0D0F7b2830571",
      "0x4c29113Ed11c6De61f380129dB01c92D2CB0C294",
      "0x37fa1F6C4EB89fD58deb4F0d6E36403804e39d82",
      "0x099aF91af558496Aea10405b602E84dF12eEd009",
      "0x866a92Dc5e706408A86948103cd834824D7f8537",
      "0x84d2E973297d123F26Ec39205eb27A6b5E2Be4bA",
      "0xe010E6F599B04cf69a57b919b3e9f73C9F3320A3",
      "0x669cF54C64B03C65cB2a14F4bbF7C8a0032CF752",
      "0xE6DE5EEEB8071Acd6238A553516F3814Cc2c6CaC",
      "0x47A55E3fAb35E8BE4Edcc38Fa04e15C51F398ED7",
      "0x94d27a7Dd665C3e4bf1091bdF8694CdC92F5fE6e",
      "0x00aB0b7d396CC2527D48ABa701414ABd4e3138D4",
      "0x99F550EE4844c9d04173224bc9247C7F6F1Bf530",
      "0xeFef67Cf26c1948fEE58E249A4248bEC229440C1",
      "0xF1FB905F4f3225684022a547441591Ff46f23aE6",
      "0x20dF7CDC19f74688d591FA3652533668E158AE56",
      "0x9B3C71951Ce43f82bf9fD748EF995c07D177A199",
      "0x015E5872dA990cd43cd79dE212D57744EF293aEb",
      "0x5cB86e7fF3Ae3EAEe54b178b53E1D68767D98a78",
      "0xe667597f3b5e89a31C10429378a030eDf0f5af8a",
      "0x414D9EC618b2EF3B1308f69e52021bc22bD0c589",
      "0x8DD405A13eEEBf9C97Adc7DCd76ED636c36A9e08",
      "0x2AB65BC37bcBFa82Ee08239d72A6221a9F629F3F",
      "0x7B5e897fDb6af70E9c582D5fa6Cc2499F1baE944",
      "0x48e23050462436e6117cE87137fE4FB5168556fC",
      "0xB7e05430b4A1A52188B45e7c69b2de83A7F25f38",
      "0x02EF21D893b94C94E25FD97371dDA2001A3F1A72",
      "0xc3d3160c39ABD729026ADb11850Ef5522f804bde",
      "0xF9065c0263F3D42b0814a7136E7057880e58B150",
      "0x208EdC22bA4490263c438d1021C0fa6595e9348f",
      "0x9092617Db59Cb5E7cEE8f4Effa0F622e16186b33",
      "0xEe9883E8e55676D4F9b2E866723590D16B0367d3",
      "0x968aCB5bB420A1CC252e5dA28F9F405EEb3Aeb3D",
      "0x96baFa3dAc92Beb1020F37F02a95A99910C2C2c4",
      "0xF62a54494485B09251Bcf3129B8143aDF6D1c305",
      "0xf9af248105B1569BC0b33f935b87Bc97fD8D30C0",
      "0x2896f175338fefc10838F37fc885aA246c073E68",
      "0x45bbC3cDcB3E9657Eec6E5d81A9eA81DC7EE9E86",
      "0x4B1fe0267f008Cdb33ad958C40f575684409C197",
      "0xA2C4773AF827F06870fE090a64bAa494e3157f07",
      "0xb09B6Eab930b51132Ba5941a07F198B477086502",
      "0xE629333691D96900646a7334563099A99F31321A",
      "0x371D821927cC3dDa38136BAcd42a97A68d4805a7",
      "0x50Ee49CF3084c82D717712eD1bc33B070F5b9b08",
      "0x7807faEe7C9275B1BdDcc1Dc24cF810eDc4dC7fA",
      "0x62288b3711C766EC3627f8414bB1d641CFBd1dd0",
      "0xd2D350e94537eD7f776BCD8cD1818e4D8756f075",
      "0x067a6EdceaEd43F3Ccd7005eb07173fF2eD8974B",
      "0xc9e652DFD8Fb2e29cF2564837Ea9EeCAAD134f99",
      "0x738d69927e5DA4860DE0eD6E661774d0687Fc1E8",
      "0x00dAB81f906EF5f2829ce47D58526Fef476B16af",
      "0x2c9CBFd852788E6517A328A21169852Dd13C9A73",
      "0x0261453eb1e8B4809B5Bc30667F2531945eD4c53",
      "0x4064512967c9336196Ecc2C1aC2CB48974A2dA14",
      "0x6d3Bd638d24868e5cC6D6F92a8C8989865775CC5",
      "0x5EbeE9FD8447b8160593a7AB463504bf36aa2E52",
      "0x06c502217D14d755A0ac3A7362a28ADFE217069a",
      "0x45d26FfAB390d8d680E5ab0C56C9288A323469b2",
      "0xAF25CA1268d263936649817b97c26bcf79e9f0c8",
      "0xFE7974C38E1388E26a53abFA829165E49D9d09fF",
      "0x4e753D84121c934595674c94E1fF4d5f2FB344D9",
      "0x430EAe29953b2a9637086734DFf781c2a2Fc396b",
      "0x396405ffBa6CD0c2222a2Cd1a30494c5C345ebe0",
      "0x5929EAA6EB31a4799818908b994b19Fd47517417",
      "0xc190D5713052ABE87b87D04658102eeF4ea62496",
      "0x98aB2907f07ea9daC1774a236a477B5B9fAe19B6",
      "0xB4dB2A7E494CD906451752Fee497aD5ab13C749A",
      "0x14b5439f9f24C4347EFDaAb00063dAC08B0B7571",
      "0x24F185222674Dc6ef7B16A26FE0DF610F3D69e50",
      "0x292AA5aB9e2F2e5eefB98B00Cd5889a4d6c0b151",
      "0x642eB022858A66E9EE67ef27D81f11f04550461C",
      "0xeD5C7C428dfDdb25d3ece103b79f0E09007a9798",
      "0x85A21bD0F6f3d44BA016a2fe5C06f05e79b718c3",
      "0x8A7812b134a55b5b2425b6D82c5FcAB5361a7270",
      "0x644a7a3d4a1e494798DD3AA756238f379242a5B1",
      "0x72d7605A3302929C46ab9d7C1587E95f298740b5",
      "0x6310aFa77Ba5CD92bBEC9959AAc67432Ecdb4Af1",
      "0x9fB0ceA887cb61f4E47d97aE08a0104CA3c5e72a",
      "0x1AD989CA0511caa74F2Eb26f8654D7e00356a286",
      "0x5004B1D07064ebE0D602cDC5Aa61023F84283959",
      "0x60405387375D9505BA77DBe00ac490e59d85DFb4",
      "0x1d0Ef872dB366376E0f2E09f89B7C66C8Ea05a51",
      "0x548f1f0BFd6b20cF23699dBB11afd743aC3AB4ab",
      "0x9E8e6353775a120e89197e40cE298EEd80D4778F",
      "0x6Ff9A081cAEE624B1C5b7ccCe92ea75422fB23f4",
      "0x0F44d604173B8Bac970Af533AA5E4f650A6037Ca",
      "0xc2FCaAEbA22b0e17A997Da90aD9860A8aBdbf5E5",
      "0xf03ec6D71d24daA2Dcc4Ed8fcc5c0bECb3d904AF",
      "0x7E42D9dBF2f7321A7ca35608cFf16DA3E84D3721",
      "0xD25F12D4Ed0E2920f4E15C862840f987bDED4b4B",
      "0x831582694545846A6eC22867136B37EB697a9f2B",
      "0x76379a7DC384572885f64368711419Bdbd4781EB",
      "0xEcf83165bdCa0230eCdE1EC36f284d196c1e0C2b",
      "0x0825eDCE5D8c809CBc23c90061e5096D8d854B48",
      "0x00c9e3c6c033e47a860f317DDD3bF5dF518b538B",
      "0x68D5b8aE80c0562AcD3E6d803fCd15097e305292",
      "0x46658d217f49b7a377f278F47Bf582730e705119",
      "0x244c4c2fB5EB4eFda35264c3efcBecAfd3247723",
      "0x37f452727Df21294caa68538ac0B968384d37695",
      "0xBC807ABE62389d2CB87D63b774706D9850DDe124",
      "0xC1f82C65dfEd807fff1BD4Ea2308927f6fE0F4a4",
      "0xbF0AD642a4833Eb5B822fC0e16f4FF6eaF4CED8A",
      "0x15e009b3372FC59e5CB173b1113c3D2b39D223f2",
      "0xd0b74e4fbdb78BE49199B5eD9ABF1D6df5F97da1",
      "0x59C132d08ca569a0d83571C2e571Fc2005539B64",
      "0xECb042c3255C62aF34f2bc9c8a59F69F62Ed2572",
      "0x4100388e8e24cF619963Fd1c5bc7463AC5eDBaa5",
      "0x39C783c22D5fEDbBB7143Cdc01d788497DEA5A2d",
      "0x07e3029E8d80357Aba0844deaCfC86c862c706Bf",
      "0x97e620eD5BB6d909095173333e9652145bCa5D72",
      "0x9593e96EF91D9354769c0D0e6bDb6364b539CA3f",
      "0xDD3908Ba6D27639f3d4E64bc78137168CBB13742",
      "0xD934EB62a73A5aC54Fd36dB51EbffB38C88Ff507",
      "0x53CA89F73bCd03598CDC4843519aB2216befF771",
      "0x9AAb970feD8A87716C9886df8D35D4E2fB7A5dF2",
      "0x0050c79eC6DBEfd58B29b9a7f4A85D17B12a6A33",
      "0xA935630cC0b5462591c9bbC88647D0269D7491aE",
      "0xCe5d3aE75F7944aE7e97f97a322F9ddBF037b2A0",
      "0x2776143d204dAE59Fb754DAd997ee1924F170847",
      "0x96D738542AB6A4175DdC2374b790b563Bf139ED7",
      "0xa71Fb24Aa29aD28A926107f668680DcBbD6AF4Cb",
      "0xbAd0AE7150EC0D0da5a3687d8ac264B61227fA56",
      "0x9970C4142c4e30B1794a27FF8CE5aA2a4A690410",
      "0x8668A8Ea6DD37d5BCfD54181AA4435E955d0A88C",
      "0x4b7e9508a388353fB989E6eD098A72a46e7BBc3e",
      "0xB7c0Dec575eCecd95Df8E5CD943e983C84a5ABDD",
      "0xAaA502C512b47a08eaB81d7b8647B6C3C16827c0",
      "0xF1154c6145885baDB6F0a99A5F80FAae73b450a7",
      "0xb44fE17c28B19F13342Cf69c5B1606641D04D1F6",
      "0x9A126eCc0DC4CA6d110D8199842CDD2C2FCBA74D",
      "0x669B9e417840563De3800f95750330D95FEdF8A2",
      "0xCF733aF6A93B7109411f9B159F53238f1f984181",
      "0x51202a023852432FAA32d27819c0610781e2f24F",
      "0x72751eaE4fD6f2603f0a42993A8De0181DD98859",
      "0x9a66aDdf4721bea38075B775127ECe5d45B3Ac0d",
      "0xB26A8C7CDE1c073b86219569096b2470D4E31B43",
      "0x4ee60194Ddf77258688F50C3dB90bDfCe1a65eCD",
      "0xDB5fC9080e87eA7C79fED93aB700a3f0DA094CF3",
      "0xACd311D7cA8d9d8652051655cb9f6446f5ba56F9",
      "0x5F48586854774f3B4BBdCA343d1662e0E96D8537",
      "0x964040E34d7000B53eEbA83Aa71F778dC4FaB89b",
      "0x522671dDDF654735E4C675B2294b4097fE872a8c",
      "0x6fF473715252B3970A44732842e0b4888FE092e4",
      "0x878bA3350ff461911FBfa435F23F5894a7c3eE7D",
      "0x5A6C8dA9211654Fd431D72a873E1C53A57219E64",
      "0xF894B4328C4461782519df9B44F76Bc0ae46C8Df",
      "0x1377a024F320204777034978664efe2a7E9C6d58",
      "0xE25c0e4016c5AA4b62322b51A0A0e4b8d8e0Da01",
      "0x61Cca5436367f848b27C7425Cd3E1dB766D29CEF",
      "0x76C8280bB72fe7B8E11B95DFE2940eDe66F24837",
      "0xFF7C220470d9fAAe42eFD730d69e717dad00b548",
      "0xaa9DeB9A283Ab796C2AD9E6017C6d186f1E8de61",
      "0x54BCeFC50a0911dd10547f43DC13063eb0CbcDf2",
      "0x8f847adb83d27F324641Fe1f3d953764BF00766c",
      "0x816BC36dB03E8CB8E4221cdF889509d79AD0067B",
      "0xf2c6fEf5B94589724D78F200916C081D3ABc7bE5",
      "0x1eDF288d80F4D597F4C69419143bFa31cd554d0f",
      "0x9879e165FFB658b5Ba9c0C1090Fb14cB6348aF3c",
      "0x0A9106FF17094f0386783e2C2DF74E0f2b3fE8ce",
      "0x160bdC6cBDe5939f198a9b6181817E42D046eE9c",
      "0xacf01F0B6c484a8025c0f6608F30221d8Def9345",
      "0x46054ef9CE7D3A0280e971E8c9bdE90e461CffAB",
      "0xbEC2f292e1ABC74f2B473e600857cF1881aF3aF3",
      "0xf232aE1F8Fc5D4bFEB23b4Fe1D915676292f06A0",
      "0x917524e9454C64B082285019bC2a7079aacB8696",
      "0x8e729F1385d7509CDb5493A00919f6d31713DEed",
      "0x69D222f5E16B82DC28620fB7320493E52734386d",
      "0x53FF6d9C338E77902E20C4a7Ea3aB0E041890366",
      "0xE6f74bE0404d42Ea47fD723653f3bd1ef85216e1",
      "0x9E5249205983BBAc84354217029Fc772eCC64a38",
      "0x960F70da4A6BB9545223CF2c03741fEB53DE4992",
      "0x364c682EDD289a9a5Ad6E07438152CdeBA1c6cA0",
      "0xB7358fa1e30fF898A8390D93dD8484a39072e71c",
      "0xA281e324dc8C4170BCbD69b99EF66Ff59338aAa8",
      "0xbBFd5C6A1D949FaF7C042123507c099e7d4BF63C",
      "0x5d8FaC0E901c2e1D2376704127016b06b7FB13fB",
      "0x78401ea53F9065D9ab451CbC62C3922378C26b16",
      "0x9488C138e0b62f55B797B449339799ba3Fb721Ed",
      "0xC2233B9b3e75ed5c356f57c7C0274Bd8c792AE87",
      "0xEe5eb1d9f7fdCC335c2A154Ee5243CdCa0D9675B",
      "0xC14B84fBC731fbf9FfDF76fAb82ed5E65a233bCC",
      "0xc4Bda3384DeD13DC86E4ec328C626D3c1073db87",
      "0xE51D732c90F9a28D6122515a3A86138f1c3fAba2",
      "0xe49142E6621920Dc265E4D621150D77f10d806Ec",
      "0x29ec0d49a2c7750b6cd66a91b8171bc6351Dd021",
      "0x5eeFE732BB838A378d7e86469268E07032aD1770",
      "0x3F843835d336E5dd19d70778266813e02c3c01f3",
      "0x1aDF30bf6104376033A1212E0aD3ebD757b3Fd5c",
      "0x550C2FeB9D261cD537DD4CA92299E0d710624Bb2",
      "0xe6C8785d249dFaD908e93a988e9cd38030ef8518",
      "0x99952FC9865da10067E079B77ABb5200CB8eEd59",
      "0x7b3243113e0D50F69ac599918b542c417E06aa89",
      "0x1Ce142d048b0CfB0829741cCCBe997a4a37012D7",
      "0x2E437bc7af6F3AbFb2e7d2fE229923fe2Babe895",
      "0xa675C0D9BA74d77A746f5b5A697a58cb85f196ad",
      "0xf972Ad6A783d3DDe8c25D269D353B58441F8D07e",
      "0x7B7a1148f816358e9b57028cadfC798ec642dCea",
      "0xbCACD592Af72cebe0ca1211E1bE8ae2194F3BA3c",
      "0x4006FBe01643aa80d7ac929993Ff7c18fCb1308b",
      "0xcc3ED2d59C87aA0e7efE8F1349A7989A20dFb775",
      "0xd48783Ad8D3C99E3c9d4addebccFB0990e9F7F47",
      "0x0A58993628f825e7D8a6333cE4f6554023E69CA6",
      "0xb42d315D61Fc007605c7A0D0cC44CF4f5519854E",
      "0x1c9742Cfccaba166d6A91c11bF1c180c322B36Ef",
      "0xC1305569C4707D5A017fD3702E044AF271A8F85b",
      "0xb7B462BfF37f717f83809e9a10841Be71d9Ea3B9",
      "0xcc05C30F47889815fA06e6BAaEDAf00b073e2594",
      "0xA9aa6E7bC4257D7DB05aEf0AAcD3DB14A7fdca2E",
      "0xedF83D7Ebb836214C49E0025EAa4caD5D566F23f",
      "0x8E3d0AA8B1f4A0FcF5e42267A6112C9b84a909d9",
      "0x827853CDDd5aE1FBD79a8279FB4d1a927F7A196f",
      "0x6165C28c04fEdc2aa88FEb165dB000FD92c275a0",
      "0xef698DdA0fF221e838DFEf854c7DC721c2F81433",
      "0x8bb8944c710725E804C0d0ab2387037be2D12d94",
      "0x55bA95596aC89f3CE70bafd2eEd084A3B41E3495",
      "0x4A79D7D3E5C0C554323D4134425ebFEA473536EB",
      "0x4031A06B9f7d1Eb3698c5dC17123e016C14983dB",
      "0x98f2B2F899A57471940Ae4c18a7FAdF6bF9Ed1A1",
      "0xf69B4eB94119c8489e4d25972606F5a62DFa28b5",
      "0x233357F9Ee37414B4D6a4A5FFFa281B06A7EF26b",
      "0x0a3E0c2f8382995E1cF60db0B82899a2F8A9CfF7",
      "0x9A1B84C3F0992d653E26b5191Dc57D15dd758f30",
      "0xe4baf0e8B7430EB93ef33F3D89E291Ec23898Ba2",
      "0x9Ce1D03bb2b88ec3745f3f400B39bF8a800E845F",
      "0xcAe441D7e326B5A54AFf5e67394d70BB4F92C417",
      "0xa7d2eA4A2aAB1e2D0e3f8bb93765eB2eCc937C1c",
      "0xC5cFf69098bCE81997a4ee16167732e019f15596",
      "0x15597201e7a5e8E6559f3B2a248a3ceE8E7195ed",
      "0x242D591BA9f3f5def29bFea1de830B69D911f18A",
      "0x53724a5c29Fb12A1Cf8125937042c2f1afE094f3",
      "0xA9325BcE64C17824850477ae229102E1F89BAab6",
      "0x8946027A68C62e376e76F2BF627dE4b195E2b094",
      "0x33e1dd280d7773A58dadA84b8250846176f75B80",
      "0xD7f7d2Ec19c2D4F8939e3a230b91A38855598992",
      "0x9e91dC659C962d42AA9055c20b6E6F1DAb69733A",
      "0x688b9b832e6087AC35b4c662476C70064b621084",
      "0xC460cD87f83f1BF4D242EEF477a7Ed42EdC0bF27",
      "0x014AF114216Ddcf57d434801D94732f8051c9696",
      "0x819cc2d52d8663b6f77d30eBA1061915ECd7614D",
      "0x42814CFb08B3a1F9fEDD618D35E4e2a156b4488B",
      "0xa2D4eCBB7846Fe85c8E8C4C1F392ffC60D71002e",
      "0x6e1A61d1EbfED848Dd916939Cf637193BCD8236a",
      "0x137776F0542CCE82116D45F04F92B1d466Cc9B61",
      "0xcB65AE5745D267257445Ff62c802224680511E53",
      "0xCd47035Adf6d197cDd0807432b423c9098Ce41Ff",
      "0x502077750F766fF8aA9F85a7A362A4cA7CbD290C",
      "0x0eF34F3f5624bCdeBA8FdfF6D9dF311ba98C75B9",
      "0xcB33865e1f8EE19da3956926f3d1CCCDe44cDbfA",
      "0xb8adabB848af1296d429F0318E4f467c51DAFD86",
      "0xBCCf3c236F37692D5Ecb3d4c96b560B7D89cA189",
      "0xC3906F6b997FdADfFc0B6C33fFb44921b7eB6235",
      "0x69C437b2Cb30330e4b79Cd8891Eadc4035D04289",
      "0x13Cf1a412Fd82e477196ef122195cDAFc100b372",
      "0x4b1d5A6410D46eb6241419491260Ee99f2086beE",
      "0x64733C66128dd9c5F086EF0857cF8Eb039F31d4D",
      "0x01Ed5242f6B4b4b91E6438Bb73CA0FA11f49A5DA",
      "0xC562425a0303ec0C9202deC22c593f09D52c2Fd0",
      "0x748d551df19A8865F1EFAdBf5d58B6B11237A152",
      "0x772F78A34278d4B10AcF3ea92a56E4c3daa7A6e2",
      "0x991e376255D4FCad006f9798586Ace003e3847cd",
      "0xD22DFB016a623E31e075E442f1b289770f01eF9b",
      "0x7f1142D901115140c3A8D6b167Fe307609e681fe",
      "0xbC5D176940e4776eb30583626d8bD7336080Ef9a",
      "0x66EAE8d4c8d8956E055ee74F1a972ED7537b2E04",
      "0xa115893662D30b382BbCa06f772c6c8691B7F9e9",
      "0xF311a9A8d3AEB45fbfAbAF87F7a44dD9C17e74A8",
      "0x136206a1BcCd0E4F96Da4B4E04D6F9f8c08B3617",
      "0x5169c7d2549691439cd8F142EAFdE9fFbE11C46b",
      "0x9407B1FDc0e548DA07847eefc1E4C4965F57Ab66",
      "0xee9d1c97e783e7FE8ca4f9355e267aEFCcdD2F63",
      "0x67c15a565f525ebd61FA6b25616632ba4cB5Fb7D",
      "0x47b8DAb058bE93af94e63204272a44B219D1dAD8",
      "0x882cE90065e26142b214391363166a7D470AE0cB",
      "0xBF33df64d595dedBEC17eaE129562E934f8cF567",
      "0xd86ACb082F635c5E6Fb57B571fdC213ccBb16476",
      "0x9E371D845b0f533505948f07e344D266aC5fD597",
      "0x53553Fd5Eb2CdB75a4CFbc05110d6c6541b5471D",
      "0xa69F670EA591F11BED8DE61Ce0E3C014C849feda",
      "0xC5DE82f2593622AE945B66652e72Ad8A641d4b8A",
      "0xA3151CE6e349439f02714156B578645988EEd636",
      "0x55bEa0e28B2c1851262Eafa70814A83Eea3d4810",
      "0x5c8164bAe0111dFD106E02215cD18f908402f8b0",
      "0x77691141eA87d47d862608bbe8F995791CB7CDE5",
      "0x99CD6caE813546FE6E0A50E4574FAcA78324Fc28",
      "0xF4BEeD0feE0EE802921b0501262b589956Be2F8d",
      "0xe8de21106FF13A70E059EEbf1b6a0bbf85C989a5",
      "0xF36D7cC761789Dea251aA9d1ba314c345FFdd1d4",
      "0x60365B58c5677644F156C3D7b290df7C8902F230",
      "0x7408F508B4aE2546E21907E7e91F068D8209d176",
      "0x87262A9D875E201F75411788a4DAfe17DFA6bd75",
      "0x32fE2BCeB8495Da5d122653B388e6fc09B46e466",
      "0x64bd176437458Ada96E7986c04Fda61AEcdF3650",
      "0xd4CCe7f79dF23b1c6283D5Be26FBD22350FA216A",
      "0x04746188B879F7A17ee930467b7CaC95305A9A54",
      "0x9Ff4CeBBe3E12F578360f527652995909357f750",
      "0x76C4BF271290219292D3e5D01A2443CbF28D8d30",
      "0xd352F598f5CB9070245eAE30349a59f117b90fdd",
      "0xc0fD5F9905EAAd0313107c6Fb6E4218058De62a5",
      "0xe6Ab78939B6caa9c7A518C9a057D8Ff5B0B396bD",
      "0x0465C680E4a9C32b946E93acbDb9Cb44F82fB067",
      "0x83faEcb0b79c5b002e75e144eEBed25e45Ae46C9",
      "0x640a00Cb506D67E0CE700106F17eA94a231Fd6e6",
      "0xc68edE5B400fe685dA90e76987cEccC640f8b1c1",
      "0xa7efA24144AB32161630178a933C4aEE5Efa5151",
      "0x557D3D1807800bd3f0e6a1D01a6D00A0696f08f1",
      "0x6239a91BC0B1eb251daa0202Fcd44d27c2EcDd16",
      "0xb3f0176b57F1468653FA3d681a8cB9C480dc1C77",
      "0xd34Fc3831255574C72de11377485D7F47bCb38D2",
      "0x93c3f38203C1CE386d6b0ddAdFc8441D704D2012",
      "0xF23Ba836FB11C016846FbfA6bcE5c5491b2ca3d7",
      "0x4e372Ad87d4389985BC2Eaac94b39828C9c92ED2",
      "0xd9f833bbb9802226Be8116DC07B3Fbb6b52262be",
      "0x2AEBCa386c87443d0d73A192284Ab834b5Dfd439",
      "0x9064C6B04DD4561555523171c43f14C2b47e148E",
      "0x2f0eaf70051e02058457c5f604E49DC85e3b3a8a",
      "0x7A702bBb7F1E271789176567Fe661E30Cd8B20EA",
      "0x35C97d85b353Ea2a0502d33Fb7206fA1931BF3bF",
      "0xD316CA92f7Eb0bE5b02a85D0B9307999741FC90C",
      "0xBB5753dD34Fa2849D54Ca622B1cc1F6F1E809652",
      "0xEE29A97873CEa4691CF84Ce4a785663bb7BdF4E8",
      "0xce37069038a61188D1806C437fC1cd8252831331",
      "0x152364C9896fec7756b1c0F4Bb8a9e0EDCbDD2F8",
      "0x795D9F67562d28DDAa65E61C0B2Ef6Ad9294f789",
      "0x0c374084dC3CE21Fab2904FAb02Dafd4ea74b2af",
      "0x6183d8ab42684AD938eF26C872A366a69e1a9808",
      "0xeDdC352116EbCdD514BE5013dd9212219531d2B0",
      "0xc433845e0872403B1D326Aa7cB7394c5295847Fa",
      "0x8f95cc7d64ac64C80861FF2210B27B8c5f620A13",
      "0x6821A1eFeac6a5E3385166c8782Ff98D210702e2",
      "0x5598498C2a98D6d3D03B484Df1FD4bB7636B4d46",
      "0x38aB191CB21939854dD7a3eD2424970b1Cbc1559",
      "0x9b248D6026260353605928978608e7347D00Ec83",
      "0x4672f6Df3Cfe68f089bfC492b34977e8e262bf61",
      "0x9F50Dc0C94Bbaaa40b93Ae80Dec92791880AEbaE",
      "0xE82dE58D28078bfc00DC987fC6DEbE0a9A564Df0",
      "0xb899247D24EC31778A332A2386dF28b281C2FE35",
      "0xa9E43Ac084e7b82CA494105C493a5320c3b32488",
      "0xeF3e22BD10ef9971Dbe8A7d188FB2ACe15545BB0",
      "0xD40A26e53f5c82Af94121d8109EA636E1601eeD7",
      "0xE12eEE2e4756Bfd57f63b8BdD951B804f5F1bDE9",
      "0x7Bd16524487264C9c0D7ddd44969D677f70C334E",
      "0x9c1A899d8BcdDc628c367132437b06DeA9fC9B2A",
      "0xffc91AF289242FB62d8D06116b2c5A6C6Aa50205",
      "0x4FeE3d8088b59accEbd4a28Ad8F2EE719C85aa40",
      "0xCBd783d89D9169C1060fC0C80638C222F90f5A3F",
      "0x2e1234877D28DA90FB7d8cd1dB704E5372eC0Dd5",
      "0x0d621BBe761B8Aad4241e86914DFc4BF421b380C",
      "0xAfeDF2B1c5460CA146897dAcB3Facd0dEd9ed745",
      "0xA47dF2231Aa73E7e9430F839E4439F3a0b667f6f",
      "0x3f1b2BD5D6A2A1Bae99436a072E9E7FBA0374335",
      "0xF8B6Cbb0356e5FAAd4Da40203d7D55983e4470a8",
      "0x2cf19C19a22079aF95FF7bE5B5A9E0a774663B56",
      "0xF468350b4982b1E09AEf2F973C7DE492615939F7",
      "0xD3C88c2F7a6E7f038e743C40bAdf249Cef7b57Fa",
      "0xC9766d873cBa098CE5fe7a3E3FF8E65b1A818478",
      "0x0d8BFa1A23bC5A4b08b9d1F3ef3a931A65f5644d",
      "0xa67e9Dcc74C269789d9dDCcE79935354b14B1332",
      "0xC55200814e9547d8e8E0C96bbe67bA1F8b47D390",
      "0x17BF8c0a4Cd7204501a45F1c203B57DF4E9FA789",
      "0xd04c5643F7C72CA43dE55e41f640D445D541782F",
      "0xB88FDA593A773a4a5181a007cC580f47103Df7Ab",
      "0xb3C20Bc54dD4fd06B72626cf595063B8BB4e6C5c",
      "0x526A84EA6EbFE1a8Edfbcb6b06eA71Ab9A336cB9",
      "0xb968e751f7C9c10Da88ceFEaedecae7f7B42ed36",
      "0x53cd272916C2C975869C4542A3904dD3DbA0e2C7",
      "0x16c9dBAd7fffdcb68A87e7728D23446cF69D652B",
      "0x160cAcf0CFD613cE43f280de0472eff8D6f4A742",
      "0xCC622f776C4bC170b1A2Ab82678389EB85c146AE",
      "0xD053ceF6EdDf6950686f836029F2C1b42CEAe78a",
      "0xa07AdE3db8F7eCB54f92f83885161bddBC422777",
      "0xA3DCd713245748B1eb63Cd77d8d28aDB33BA83aD",
      "0xfa41E94d0a1927a5b696A710096c2B302Fc4A5d7",
      "0x6B4927B41Ae43fc2776f41D4895d08754894caCd",
      "0x733B1BF7AADEEeB3d088E9b10FC170E31d45102f",
      "0x7ab1539a8a6A83d147Af6165814100c64f8c8502",
      "0x68F01862013187E232A2e78b8EDFC66856716328",
      "0xa263C99eFB5c43FF006f522b6bF38bAB6Fd17a14",
      "0x02E0f3F37499AB41eDf6776f0854ca0E7Ef7317D",
      "0xDaB005Ca3a6959dF5De7EFDB6008F365826453B0",
      "0x84839De20e393b6973b28EB4511033d04afF7e77",
      "0x92BC7C1268d440cdae83CEF3796376Ef3176e3B5",
      "0x1B5195b134e48222657450F6ef8B1BF719A861F4",
      "0x450De4a92e85A5DFe9786fFaD4f432D4afe37A1c",
      "0xb4827667DD5978Cb2cab22351f4C30af6A57c663",
      "0x9f2CBfA2e29C0F0894eee8365fB21495D3F00030",
      "0x7b090f85a4B9Dc12D90eD457CA8F3fEFBAbF878b",
      "0x499951495bbf312f796bbfDA606B3394230f4f69",
      "0x996217922BC3eBebcCfe7eA8c529335Fdc282237",
      "0xC4d30e300258e8A18f39450DFa3781A872bcBF16",
      "0xe31830c855D28B46f021B1aE109A7EF4D4db0A04",
      "0x464876B680D43969cf17eBEb40c36e6AE1966706",
      "0xd4465751B4EBD4E9E3289D80730d4571A0dC9c9D",
      "0x04d23910096BdF53Ba8C0a8b63FBb027a84bbAb5",
      "0x99a5Ee0fCf05489AACa1046803c28e5BC04D91f9",
      "0xc667Eae3fD5deac27D42cEc0536f7B99C4cC0bAc",
      "0xD89fdbD3173F334316E2CF6eBfB53785B7Dc6d21",
      "0xE5c54aE1a4DC15f592e45fA72DBe887464cE80c5",
      "0xB6bE4A1Be21290289fdD176899810ebf22244356",
      "0x7c425f9909A1adac584F174E95228E67D604b6E1",
      "0x818587Bde62856d523E62ddB897A00075f59923C",
      "0x57106295E3F136db7925b8841d107Fea90cF1d4C",
      "0xE31bF016b27787affc6AA7DDb322f68239DADfA0",
      "0x269Df0095122b7a6C352075BAbE3f9784AE008Fe",
      "0x265D5b6ABDeaFcfe41a6FD9FF445581Ab8c40A67",
      "0x792Df24f1B72bAb447c1E8CDAD78699c900594a1",
      "0x1F719bC2d7432b0e97f7499fC601D5f1Df742916",
      "0x3543Db8AAEf426BE11a3daD60fdb5C1b20825cd3",
      "0x9D3da1F71F30383Bb95819EDF8440EC56884B78d",
      "0x06b4ca244AC3fad7DFceB8BD5DA06A26b3DC85b2",
      "0x726FB00D4C608e8DBdCcdBF16bb04Cc5907Ea709",
      "0x67CA545F228bFD2033d44e073457Ada920Ac59Cc",
      "0xaD6608F39bd519A9a31f165beb44c5f5406442b6",
      "0x761673d2deE01eCe5E6Ef860AcE55506f05B3D56",
      "0x00A98F0Fb82cBd47e2Dcc3ae27996f6128099eDF",
      "0x8eCe05a5d3935712dD9b7F8E4000C1Ed12B145e0",
      "0x65DaF5daBad773298eA1552D4e528D05c33568d2",
      "0x4b5e6685BD2092441865C3121a896D282F01dFa3",
      "0xF5C18bbdbAdc849288196900f247658fa8ca4A30",
      "0xd5e6BaF0b2DFC7aB305c5B570c12aa77e0908F76",
      "0x88F90463467d2a31EF69e139FA2056c7496543c1",
      "0xb171cDCC323543E1D4c8A0392ADb5FB560C6ff9A",
      "0x735E668583f617650B65caDC786aCfbE4C83Aa05",
      "0x049638483C4E19209c971bb92063CC8D02854bcE",
      "0xF10B15c9BDEE17BDCAF4e63Af53440CC151BA636",
      "0x1B788CdddEa7b22eA4Fdd2C60c69E26ff49eB8e2",
      "0xCA4a67B98b59D8f2A0455D1F5031fc993B250cee",
      "0x3cDea0aA32167D0152093065F1dF08477f4A90DF",
      "0x6cC42068d6f94fA659eb8c21B284bcbeC987936f",
      "0xBdd12A57eD43e94Ec2C685A352A6941FcdDC2A5c",
      "0xD4E61958Ce7c161a140820c24EE3Bec7d784fCBD",
      "0xBD70E6B7ec66207BD1A664177965695088b0fADf",
      "0x652f24819d86f78A564AcE0953Cc8A2C97613cd1",
      "0x3C17a4651b737B185dc3a284844E334db1234b42",
      "0xf9d7753695B6233Dd501Eb3367A5A51bd701E5a6",
      "0x2a1F5598Af9741F5dd45AA22d1f32D0b39E9eCAE",
      "0x093aF34E7C8D80572A72855ad2261573ff8601A5",
      "0x94f7259cB3333fd27A24491150437898a38d8F98",
      "0x519902d47FA8CDd5b4CC28FfD69Ea226136224b1",
      "0xC54aF3D29BD6B027D772aFd693140ADe5a652bEe",
      "0xdaCf492D97b1bad893C8D6d87644F9132665954e",
      "0xbc4E21d40028CD09d876148A3c160B5B95cD5bD8",
      "0xb288C329037a13D8B95011Fc8e564d02D357bae0",
      "0x0A93128f211dc92595A4D99A4D0e49b02A853143",
      "0x4f5bAA115b771861230694AaFF2A650e0263f523",
      "0x60A4283F0F9460c91e5eF79A231DD68AE9FD7022",
      "0x2f85915168C4af953f836DAeD84Cc970FF584edA",
      "0x75a6275b251d31E9bd45A6f44c5568BceEF06123",
      "0x4000c1F8078A6C3c2fb466AE957c8757Af63ed3d",
      "0x8Ae0B17D1A9AD3aCfCa6aF11B28Cf9337acbC731",
      "0x9F223826C61e049Dee78A42ca42e86634abB4532",
      "0xD0861C13908ACF4Cf5561C8837C3dAbe772eF7f8",
      "0x0e38c08E1a0f45BE522124376Cb0C7eE92154C01",
      "0x31E3B1e7F77EfBcE037D9d571D034fBd2CeF7D90",
      "0x16e07147DeC83399cA9035B45E792C83BEBd909E",
      "0x2060969F5bfFd7A273c916aA98F479bc7EA198D2",
      "0x4aE2469A9c7787b79D9B918d62869416FD927E7A",
      "0x220DC397D097A5D045CB3E383900c64ae6d91D1f",
      "0x271f94801FC8f33Bd525c3Cb94004aa595D6828b",
      "0x3C22DFD601E043Cf0deE9A05399e25e86299A5eE",
      "0xf238B81094AA416626B38A168A84bd5475d89809",
      "0x1Dd29e8C2c4e8D38e4F5b6Eb1231733C7ec92E44",
      "0xb90A57D4a1c50A124E57DE13A9e495C2048fE6e0",
      "0x2eE6B07c4B9a4AEbEAe733E4a3e0d908dbd46fc8",
      "0xcfaaE06c9235Cd41f6b410E9B222716AEfEa5f31",
      "0x9ACcbF1fF2b1677FF61307a0DC59970ADD8324d3",
      "0xa5a38B388747cdE317b55d79b630657596ecBD75",
      "0xe6B8cf22044536944Dc7F8D7D1cec520Fdcb946F",
      "0x49257344caF3DFf71aF5311E845A128CC9Da7914",
      "0xE071bdF3f14ce078A216F76b4073f332c4dFA5cE",
      "0x47D2FcfDc6127283edEF306FdaAC653d0a4F19d3",
      "0xa1D42e23921be597bD850620CCd7ea4511031d8a",
      "0x84A5D9C2865174Fd12F3E4A934f8ff732e787086",
      "0xe2D75c77537EF1b01F4420d0E13a057c7a12d843",
      "0xd65AA2A7989b848ad4463554Af3160703C1d3AAF",
      "0xaE5385A58BE08D637318841d55aC91E146Aaee4f",
      "0xC2f8Cd91F44a705e34a43a5D9eB8de61Dd15bA65",
      "0xd2C4a905C4E57C3b267306E30f8b65Fd746a56Eb",
      "0xe9b82dEe2b96FA448bec422A4CD68652D4f36377",
      "0x937BC900585e06E96f99457BC418Ef76606659d2",
      "0x49c24a491D9bcC231Cba98737193dFeD9A8d5377",
      "0x033452Dfa228663296fa9a2685560263e4a5d428",
      "0xBebAaa6BeE0bA0e80867Fe21C9d42eeCd76c0a69",
      "0xB5b753525bFf64FAC1d1CBcf24516c9E7cFe8B03",
      "0x85651896d55b3c370D32e7Ed84876F24b51eF035",
      "0xAbB9a1Ffc3d19d5a211db333880812c86973cfB7",
      "0x7F9bC2AE501919DE512bA4BdCfA83800cC1E27e1",
      "0xEFe5e433187e393807175450BC2B74F71a86D03F",
      "0x63a45d31D22aEf4630b9b2058cd4516823e54564",
      "0xcD0101e80c8af522867D2FF52326B4a263Ee45B2",
      "0x1ee72112ABBB0561B3596f631135cEE8B4A72918",
      "0x49f8622F0774efD52da18CBDfEfd145B1f1AB98F",
      "0x6657d124e1687c7EB0D447d336472e281f9728DD",
      "0xA123Ac0Fb07d19FD40ED8A75Dd50c3Ccf767EA56",
      "0x4655D9d646D93655217Da3DFed0a417725B4734c",
      "0x46b5AeE9cF6284306725362A43c012911118e085",
      "0x8acC674819064088C9E4859Eac3423F65E4b5a28",
      "0x206Ed2ed9B718643850A4fe5DC4CEa8b4D02e9d6",
      "0xa8eF5354426f1A2f24463F6Fcfc3b792FFA898A1",
      "0xD2Ed2774c8425F52CB2A710f8122D979ed789154",
      "0x80154A990958Bc8cd576B549baE2C9874d969D6A",
      "0x39b5ED0Cf9C14dB591b112C6dAE8d6631459Bdf9",
      "0x79Db3F47C5040Fa4e4D2A64499776812a0CF0879",
      "0x2CCefB7d96525Fc67182cb1B934C97116bc3AE77",
      "0x780a124BfBf8A88e65A1Cd5331532989b00F04c0",
      "0x5DC2B84856B4B7d087974113BaCFaF3Cb20a635e",
      "0x7e668C71D1a53fCDB423154520A25e5d1b2d0c8a",
      "0x99F3cb2F7550986585d071dF35b3715fBcD9b84d",
      "0xC66D47b79014D27234997751d660dbB8d01473f7",
      "0x75226B4133d8E78293ed63Da62A7F855E969C5f9",
      "0x3420Db524706a9843Cc969D6116C39b4823185e1",
      "0xE5E78FE53AcEb4511442462833361DA4b3FD829E",
      "0x56B5537cf5C680f85aCB1edfa677E5Cb67F2974a",
      "0x01e4E6ad19daBC79b5Ac0caeA9585cCf4Ab43665",
      "0x2991d420904d1E8F614960472765D87a542a1426",
      "0x2eD9681D2043E7193a49c07Ef140D151Fa49749d",
      "0x3bD26C893BE99B94C69B836bEcD2753e21EBAc13",
      "0xbe3482E6D8D60E534cf477507bF0fB5794caBD29",
      "0x4C627dD1ddbDC4d98Bfd317bf3e2c2a4Dfee4718",
      "0xa144e1E3e7349F77C8962C9eaA1836217E447FB2",
      "0x530a90F981cFAdb8eB7176fd5Ed236BF8f3ceEC6",
      "0xB3dda97Fe3eC9516BFF4149217C7Ec5Ae088e778",
      "0xE87ba120F5aAC5aD75348728d533247A98cC7D9c",
      "0x42d5f0FFC0b2E7CBE553505ac4738Ed602Aa822D",
      "0x83794adC370E1F4249E0059d41c1F815A4eEbD6B",
      "0x84110390A5f2d7c0222fEF897DAe188f7272a4F8",
      "0x31E35c094aa090E4e63B3c81143FC17C829A5389",
      "0x62032c9b4Ac190f6925bD4639f00299c7Eb9e991",
      "0x8317704CbB9e55Dc7A618198bE040F41A5AB27Bd",
      "0xC7C26a3caf4531Ae26a79b6d7F164177c6949F88",
      "0x0965AC8cb9Fb2F996a73ff1532Ed5F737d6D1bc7",
      "0x2522310C925b7e5E4Cc2f2bBcad0fe1Fd14fa091",
      "0x78C3ba2530AC341b1c07D3Dd698aFe6898842557",
      "0x1e7817Cbe421cfE8F89BC35d12ed8043B9510810",
      "0xa0e2B300339bfA69977dFbE062BE97aA588a55d7",
      "0xdF9A7866E3A07aCE87a0Aa915C425F91789A4105",
      "0x2FD66Ac2987266F4894d3b2Ee0EaD19BF1e1e299",
      "0x36e035EdEd495902e01508d7a2d17b9a487D2Ce5",
      "0x788C935FA885c447a809b30B7a85dbf3A99e4163",
      "0x0fFF283732Edb007d84b5444f1Eb3E44cD45CBd4",
      "0x217A681c47Ec8753493cD91CB54a6f769937ff22",
      "0xBe9937590c4d65C9415DE57cE5CF6dA0cb356316",
      "0x9e4B75394df00ff6D90602DC3F3af012C6921A6E",
      "0xC0FEc9FdC475e1bF6334A1949082F3ee2Fe188e9",
      "0x78B7D1bc5C4891987014F3521831Cf2322Cb1110",
      "0xf1C2ca765517000748c74778BE67121370a23cf0",
      "0x411FBFf500Ddc337EfE9CA18c69437fD690427f5",
      "0x9D377cE19F18BBB928573066eA3FF9a9CeA57e4A",
      "0x20D18f59B42b1424441806D45b86078A76550eb2",
      "0x35f97d16604035d3D9069f7130f8F827206841b0",
      "0x8d1001622eB606BE03C51AbBde0809726A9E8583",
      "0xD4F4cFAd2c762a490999BC921E07287BA3991f06",
      "0xFF862D2d2152d83c8E286414aC506d79C91732ad",
      "0xcBf571f8A95e6774F4a8f5fc5E39E3B03Ed67f93",
      "0xb736C6795f323352c341aEC10427A4dA2aa71E04",
      "0xE12E59DcD8135d3A2dB66CBE55C5F468Fb0E0352",
      "0x3c5F32d219DA83AD33F2F481a414E70337A041cf",
      "0x99801d607dAD2708D5b6c8754286DDd09B987ECb",
      "0x302Be3ef4c4769A331338976a6c90476aeBA44BA",
      "0x4030aaB07213c5149a0c23B03516088363B312c1",
      "0xD5aDF0884B4E2d1dC4908977d95356e592d39c01",
      "0x9Ea3Ab92328c302cec8CD34E2404398B1217815f",
      "0x7329Df73f1e922592055389faCfdC73E46Ba4B23",
      "0xaf87d161a85a9a2ba7845c92D103B1f8B36eAbC1",
      "0x447685a064783A6b401235D286a3B62Bc915F52F",
      "0x6B42c698b7FA477F4ab3D0C5AB6Bf80515C30148",
      "0xf6AB5B77DCDd13b3efd3fc1394dEf92baDa530FA",
      "0x4685Dc7305e6E16C23Be9855708b24BF040C1BF4",
      "0x1D4188F6200Fc43e873c90fE7F2F1BB2384f0f50",
      "0x80fdcBB3228812A9C93e0FE984867BfCD9F915a4",
      "0x4e89C6475A5A88e7316cA5E00862E01007906C69",
      "0x16848571B450A9aBFA97d1A0159bA71ECFECf204",
      "0x7D51F2F627f5FA5c333B0ba6874Ba6D92D1145f3",
      "0x4ca8d9D59f40891a07e75e8dA3Dc1c15a2e00B58",
      "0xfd2634F0d791d59E46b36f80bFE960E39D2E9019",
      "0x725A17b73f06f412A8251e305891030fec6fF3Bc",
      "0xCb6D7C603BF54C3F8881BC63Cc7339a65C2f52db",
      "0xe6D8433e0B238Ba4956768c9A0037e8013cFA67f",
      "0xA3784D81226f4E1852599dd950c78fA288125C36",
      "0x13FF993993B5b4eF3a8a6b130D689B7Fa8dC018A",
      "0x3db12cd62A2A203B9b60430C47482CB8C4a9e60f",
      "0x46CdD0BBf655740df3051892cAEEa6731F4165e4",
      "0x4e472CC54AAC2589d5E4CafE36E958a93D1d1150",
      "0x6faE085fCD63c0af5BBc79b685994d01614dd2A3",
      "0x64917F921146d2a74B205FD5BD544DA66b194150",
      "0xf9a4aAa14Cf02A1F8ceBAd4189c8fF6cf04b7878",
      "0x92cE3E17c74486A265E5B8A31406B808dfE3C87B",
      "0xb07Ce86aEf83307d12Ad3bd10A10deB52D84800b",
      "0x8a1aF1cC93C4d935ed4298BCf04A36878Dc17C31",
      "0x9d63cd0Cc9639B319bA6eaE836ac2c1d2043F95C",
      "0x3830D533Dc44C0493F3b08e06b0F8C2F37F88601",
      "0x1B6b4d8239d148A9C6538b1De1Feea2438ff5f70",
      "0x864138259CB28b6Dfa925cf9eDE65Eb65B515B60",
      "0x052B6caE575f63a16a4C98b8D0b87F9ff40C56b1",
      "0x24e9aD204D2Cb1A237BD6f17E16548c5f99501ef",
      "0x6E0c7Ea832C8974228d051245Ab0BA8678433bd9",
      "0xda608d4e1Af016BAa774a0682f5ED212305e6983",
      "0x26fF6A007A8c3580E4C2785351709a375d68f359",
      "0xE9C569015369d356aDa5B99912E1b09F5499d9Fb",
      "0x85b6b4fB88F1c2e9F84e6a72C1F9Cb0689ebbC3a",
      "0x13EDAC523C7375D1d72fa0356fea32965C419643",
      "0x876eCff5eDf1DC4CBE3Db5e8b3935d68fC8bBB88",
      "0x6Ca59cd42776a80372a1BcAE0C2498e74b99A8c6",
      "0x1790b6eff71f924d7af4195df4010ED00F624A22",
      "0x1DF01250a35ac893Bf3253666B0905c465d39B6C",
      "0xd0A7624769Ce099AdA8A7410644AaA294425C7C4",
      "0xc61975404837C557BA0258817b758dF8A4c8524E",
      "0x3B5B1E997b8FA913210642E563e004a5FfeDFFD2",
      "0x2d6b9E88D17885eFfcd87AA3Dc71264732CA1a04",
      "0x00C45835Ce2e61998912f1D9b2E24EDFDCbA4f92",
      "0xDE0C2c704809095B5cB6Aaa091F28242068d4887",
      "0x98aB2907f07ea9daC1774a236a477B5B9fAe19B6",
      "0xA3393cb774bfB0dd058a542A4aeEd69D873e88cb",
      "0x50bb5D8E092338F18B4fc1CB1aBD5E1A09904E4b",
      "0xf068E15B6fA01E363986eF5E58B7Dc10a37ecf07",
      "0x0A0e5Bcd94ce6aE48739dbe552c7f0813736f233",
      "0x879286e4AacA0a9A1847B1D951b0ebfcf2B0E4a9",
      "0x0e1114CB100F76D52e090e748CABeC1CbD3fD1D8",
      "0x138f1151e33e9ad1acc5b421e1f69da3b11f337c",
      "0x5f05ea926d5A6E16C7d7eb57DaC86671e7214ce4",
      "0xF25792FF368a7ECCfB06dd5CAaD84A322b5Ce6E5",
      "0x9749c98e4c74005DEd4EfDF4a99A1680aE3Dd084",
      "0x5F1fD8afaA2E91cE2c3505301B54398a51Cf7405",
      "0xa7Ab98904aB88473FCFA88A6E2612375051EAd22",
      "0xda466E918ea7f638Cf97d9F208d6021bd6C5fc4C",
      "0x0606da0C5Fd077ccA9680c91eFB82568670e7683",
      "0x5bB36df9D6D1174E0026aA66f665C0adE2482D91",
      "0xbDD7b1A96B577F6A542C1160b79FE79d307Ef212",
      "0x7d795D9703d6b9D850C6Da5b99d874cE06CFd29E",
      "0x363d1cb4d2a05bC1E162aB93a591d4Bc0bd0846c",
      "0xF8c246bE8944C75b661B9784c9BFB56123C31155",
      "0x565D3F450c79ae1323dE8C9Baeb6bF912D2a539C",
      "0xFc550C36812C7Fb2AAA8a63d55a3af7f95c80Eb9",
      "0xc2145aF68167E73D9b749528Dd929DE2C7408155",
      "0x7B001511b42e1C55C3fDEf36B9fDb25d40c48def",
      "0xb0F50811E34607b05DEaa1D04f60A146B50E7C01",
      "0x1f1EC5fb229F1585dC7b7a033637eA290bAE1C50",
      "0x0aE812EE8f238506d979e67A5cb25a8dAba90560",
      "0xCBADb02A959CE26a188c548DD4F2bC1eC6eF60F2",
      "0xf64064dDa8ef263EE233B7dde485C2Fc1524d488",
      "0x7b25e345c74a059ef78346529c2f1e8f4c03064d",
      "0x53EB0Ec0172F95184E9EC8493fB13ffBE4a9f737",
      "0x90B25047d196C81D0ad91Ae380BEaa2fb4be7C7a",
      "0x26fF6A007A8c3580E4C2785351709a375d68f359",
      "0xf9B45B8ddC4f04587154FD37fee6F94ce7B07a50",
      "0x34C50c291a1EC9bCfa18b4167e5f9966A5fe5c6d",
      "0xd7FD5Bfa8799b087FbeFac1B38B6F39fDCc9B7B4",
      "0xD6cFFF1A553f7a3ed9D3e5Af631c8956c7d585Cc",
      "0x13DfDA2bEd52A21fE8FCa869994bF6503CaE431b",
      "0x681434C56fe3DB55F5D51322C732fd73ECfE7647",
      "0x174Aa0Ef8719e5f166237f9B7a4A6eCd938971B1",
      "0xeE989D06dC58607ca4C2AdEB25C1557816eA1650",
      "0xA4a931712BeAA20394CE5E2890DA6Aef6D2C78BB",
      "0x29f49C733dC897Ea812754c5b8bE1f15b2728710",
      "0x6BFf88eC80dCB95566bf0Fe25eaf0E413B0314C7",
      "0xEB3DB53788532168293a1c3a80d2FfFD63d7973e",
      "0x2F9eE0Dc4A1b553193aeF42ae258Bf4351FD2023",
      "0xc12d26CDaD38F6AC1dFA350415cD4c8005f81720",
      "0xe6a454e073DDF4dc30D7352d410f210Bb767c35F",
      "0x030A4e6f6a79CA23F1Ca21a9fE62dfb5f0f20508",
      "0x92F906889c462CbbC5877a55fca4ba3FFbaE7Bdd",
      "0x44540FB9F8686E0d095bcD906bE4C4890E42Cc82",
      "0x4AF765E3196e417C1F0Ae0392Df00912d74d445d",
      "0xA2B81c7Bac3D70a38e26D417200eA420a9CA7B54",
      "0x995A74c733e6C5Cd5470aB6066C674FDD9a974Cc",
      "0xD3FC3daA88b0a7F66fa16321FB341F1DD46A3129",
      "0x22980C7Fce12204e99dC082f411003520D750D5E",
      "0x5E5268634855806d0Ce99D78599083864E5cB5C8",
      "0x5fb0675b3544b7242bd9bb352ffb8717f5b19de1",
      "0x7caCd834f894Fdd62230bD6E4040a99b41681b79",
      "0x3D6e8D3a755BF928B86ab0801bba01842c7c200F",
      "0x2f53B4b24D226947614696D901f27B9cABbA8B64",
      "0x27661be4654F52FD84f098fD9a3f65494277F6D7",
      "0x19470A960612eEaC86A95d8f748acBa9354211D8",
      "0xacCF2BA1882Bb42d92Df8FA99DfC1290daAeFEAF",
      "0x47e61395E16F86dD1AC7F7B7b5330ee90EC78669",
      "0x2Be034FE8690f23Ba5B01AaB0b766765a6FA39f4",
      "0xEF192Dbe396f11b2e10BEb2b91ECf5AAB29E3248",
      "0x0001dcF7e98C68Df025aC6A0B3294997AB914aC5",
      "0xf6D9b51b9bC5f99BdD2b26a37DaFEabd5fAa6C5f",
      "0x2906a393Ec28aBed589e3e389741B17f73783504",
      "0x803c20Da23f76152cDEEc3A284876b0749218140",
      "0x74C3c52b88000E54174747674109fF662E425C4B",
      "0xF101C065C723E1c075024aa9420e232C1DBf3B9A",
      "0xea75763cd6ce798296c15775dcd8a1ab2b085a39",
      "0x2ba8789E59E9A90218d43f699d25BF85B5cbadbe",
      "0xe7D1d931B8138e1863b3B95311c4184B4c141db6",
      "0x07ab8da8E564045d87E5e7E6292Bcc01dD819767",
      "0x09ec67abaA7a39b6057146F90dB65b715eac1c8d",
      "0x5d7280e433ee1fe8E20d988daf42220D492c0037",
      "0xab47440Ac30F719B675F0Ec314b70a14D663AE43",
      "0x3d5B24eC0A37154747319C70a3502Bc58339B852",
      "0xA83C0e9553374fED45CCBC1F301D1E72c5dfa56a",
      "0x45A8A8c18cF34dF79964A955829E71FD52F4039F",
      "0x491AC59235f6b426115bc6cCa2a8d7158a1a94fB",
      "0x84F3337c0321c2fbF0ae85AD7a5442d289d16a30",
      "0x0f95348DA20C95af5684Ac4b72655380a2B883a0",
      "0x99a5Ee0fCf05489AACa1046803c28e5BC04D91f9",
      "0x285483eFE156B1273AF85233a8a2ab7a40A47150",
      "0x3B637116d3A247088DD81B5E923ed9E1Ad0CB043",
      "0x09EE2bA0D457C05B93aFB951F6F4C505784DA499",
      "0x1c93e0B8e6dc10833d197D0fFB1D07157d025F4f",
      "0x549530dE6940a59E9ac4CB8Fa183eD55E8eeaCA1",
      "0x4e3eB9F803734821fa2f58B812D9a88b524dd554",
      "0x46dDBbc7C4bBB8FC28Fe9A0cc36FC2DF16CB8d15",
      "0x32caba234839cf0207c805431F99652dbDBCdaad",
      "0x8aD7bab2e4017164C9EBB00A1413b601c86Bc1Ab",
      "0x527a18AfC57A6D97b63a8f910F6C5d3f99f819A3",
      "0x68Fd9Ea6b0fCCAC6Bdb283826a84D64561b98038",
      "0x9166d3fA2023d109CC86aE585b96e60511526CCB",
      "0x2695168CF9d908b8352c39bF412421f5aAbcC1DD",
      "0x7261ce440979faB26c5Cb364000198e45EF0248a",
      "0xcF5CeF626157De246fF0dcc68F7a8A1f38F283d1",
      "0x7A2d7a407C269b07ae88C902014c28b7460Bf51a",
      "0x58210d505f6DE01bc961bf35f55fCF6E681cdCD9",
      "0x86e20B9c7eeC0B8e320840a1055B03Ff0136B301",
      "0x119Ad0cFFd566eF97D41F4DAA7976Da9991D2C73",
      "0xe104b565857E151C35edCf65Af65Fc1FA983130E",
      "0xbE3daeA9d613Bf2c645503130D172863b10334b5",
      "0x426E3f485920508217aed8D63d3Ec681abbEAA61",
      "0x8F4D17ECd4f76c1e9c0839a374b35E680752ed40",
      "0x29AB4eaa9280eEa2C4ebb1E228B42C581ED29eE7",
      "0x5194f9C812D95C80b57e7f58829660087FB3edE2",
      "0xCE246caFA3B894c5165724366A0Ec2A20AD7b351",
      "0x1845BA9a6D943A95D45B0F2Fc5433da0191927C5",
      "0xCE77f5B982ab76466FEbF67cCD53226ff70d161C",
      "0xBC40dE32F5C199ab3c924B446c90036CC9cE4718",
      "0x1329c68BF278005eBf906cd629a5c700520b4635",
      "0x4FF585D349179Ce08057e55E72402369edCF9601",
      "0x7B3725B63827b82Eff95FE7716c1da35b4FEA8d3",
      "0xAfDA82F8c754cf3Cc0344850B81CD9Ab03B8ADA6",
      "0x133fCa45f56DA1C215eD2519F5F04a9554ee2599",
      "0x18Ae5C08908686DA00F8404E0F48703C37b4308B",
      "0xb608B0bFb13e79e307Ac5cd5dFCf034D7341459c",
      "0xDa4C9f91E34bc3dACD3da65C34c5233868Ed3c51",
      "0x7428Bb341FE9f1C9517918b0892c6Bfd95e4957B",
      "0xA4DeBC77bBB1DD22c49809716D1269349474E05f",
      "0x6Ce8A7b0032ECA57d70FB83EBe5d417C3d52f1Ab",
      "0x01e61425c217E96BB8f8fA20DFDA0d95b53897b4",
      "0x5b6F143d949e822eE60741B180D36D3DbC5300E5",
      "0xd5841a2534ac2C56c0FD6336B1f9Ef4059b9BBcF",
      "0x2bbca3C4Bc79dB118E72aB882723CEBB4771Dee2",
      "0x1A89640D149B252aF4D5979498d6ff0e7F15c702",
      "0x3125554a5727DfBD08e185AdB60f9bD4c90cBF6F",
      "0x49f54BaEE6E07893ebCb64B13a27d1413A2b3AEA",
      "0x490d754d32F9ff687D08EdD73B08b24d84e42BA7",
      "0x5Ba24F7Ec27C722574c21AB5976ffeA95CfA8221",
      "0x4e126e71fc470be4f9618324316655eaca2b6ba7",
      "0x4c993000e62FdB1148a8d383A44296211D33D695",
      "0xb2F05c206f192d3bbE8C000d47429AA2887C839F",
      "0xb458b2e20da1CfDDb2A94Ce492737cB22BB00D8D",
      "0x30d8AA345Ad3BC696209D40C8C099fba9c7536D4",
      "0x4F8346dD1a38FB2546AEf2e765568BaE0caa0C1f",
      "0x11737563dE4F0AEb2Df3b72AC8F2BdDf4c9911c5",
      "0x6B2001fA3031995029B3B4345C8AF73c8743Da16",
      "0x5936F2f5949009b94d5171E803519Ef4D403A62C",
      "0x538C6dA871B26950c203285de031f3a2Ca9BB80C",
      "0xa9ec29913eb0b36fdc93ed93c063662a3ee82222",
      "0x08AC1D3A8F3b0Ea73f3011909a52cAc6350d2863",
      "0xBfce5526ff385702374Ff417Aa684b3aE24EaC1D",
      "0x4301f434953d930DD06440F671B783A23eDC6d16",
      "0x7AE64ab8A91fDC9cF63576A21A9934eF152B1500",
      "0xdD03b42Cbf353944dec711a3Cfe5c985F9424C38",
      "0xb1F37147efa4D46AA5A468C6fDB4854EFd34809B",
      "0xd9eaEeB944750661D67194b3d0CD60259A3B4813",
      "0xD86F58CC28918B81727eB8970bB8B670C47167eE",
      "0xEAF2aB1770c5b4FA2630258b61D8981d1a9c62FA",
      "0xED1F67432c6E486c420Be374c1F89B021c96DA6F",
      "0xB9983C33fdb015C726bFA30dfd1aC8ca196b4c5D",
      "0x79b706470f7d99c8Ed94cbE98d7a01f0D859Cb03",
      "0xe1B692193F76C3fB53fF3A8D2345171F362699aB",
      "0xFbE2878eD97d2DE944f5e183Abb261178b340395",
      "0xA093276F7De1d8757812f14C772a59bF6cAE12Aa",
      "0x9e37e78D7A303C5F5A67Dd582ae9a7AB8DA03845",
      "0x2468e0426a036f71E81653965f8dF0d7F2E36D7e",
      "0xC903E67B00abf8a9436B2395A15e3CbC2dcf7448",
      "0xD4c3BEB072d3a699cAfDf51a93F1af539a7fcC5F",
      "0x2605f59352353276108bcE30de305E733cdf85D9",
      "0xCB289C8CD7c050846788af2f35440d7787090B8c",
      "0xFE7974C38E1388E26a53abFA829165E49D9d09fF",
      "0xaa8F52Bf999Cd9C32CDe0e435b9696c161b28076",
      "0x2E500AB50282d0094C748d461dA4bCAb8E70ED36",
      "0x07ab8da8E564045d87E5e7E6292Bcc01dD819767",
      "0x6122806f509dEE851148e1526A637931D33066CF",
      "0x21F1A9eaDf2C6472f7ad13ACce236C50c0226FA1",
      "0x2EA2e5e16d5c990c49884D8a7ccD78a9CE141484",
      "0xc68F9bC52616557D597D9D7bF3697250234Dfe1f",
      "0x27778af224d6Ed3baBB98B7E9058B23e77D2F924",
      "0xdc492C20d2fb3E02999C3dD8f0FaaeF64497B76b",
      "0xc6FB37BE30A88518Aa9c9Fbb4b5507689a1dCe44",
      "0x30A353D599E1dC90D5e7d0d99027e612c7F1624c",
      "0xBc2Cca34E9a11919efE17360bC985b0380e5a924",
      "0xC170CA35C490FF9F176C93718fF54153405da426",
      "0x19815Dd7FD0182C2AE9B99b7b03799014b5dD04f",
      "0x77c4eb201D0118d23Bca042eb475f0dbF8aD013C",
      "0x48BBA37aa03aa740810A0FfEc13649AF01e40Fda",
      "0x922933E8ddE4Ab35674F905243f238e0Df6Efb8f",
      "0x0395ea9aa8805bc0c05dbaf705ba4242e370601c",
      "0xb1114449DBA5Df8300045095543D74061b1f7e13",
      "0x3471E36fB55968C222881624E430240Cbaa34556",
      "0x031B9fEe3Fa957D9E8Cbb2790477266bFE41C03B",
      "0x08500a1fbfDaD40E68Aa1Fe03590aa1A89cb1B9E",
      "0x3DFD58e8107F4DFD98D628a3B2D344281C48AAf3",
      "0x710F1ac6f51F83656247FAF240B94cab5a5AE609",
      "0x5F2a8E59fAf8011aD6E280aA0d78946CED67a0EF",
      "0x7428Bb341FE9f1C9517918b0892c6Bfd95e4957B",
      "0xC0bB7163Af207F84dF0815fA5b533577DDAbeA4D",
      "0x2D4036E3c6C0fEa6CD8A31984Aa2595A61Afe277",
      "0x361E8942eD2da58E3AcB3b6A004D71D00D842f9a",
      "0x81C23eD99EfAD1cDB3A7a42cFc3324a4610756Fa",
      "0xf6422eFE2df9BCcc90375a216899eaf181CeAB50",
      "0xffdd455FEa2EA40C04390c9530EA8A64D9517D03",
      "0x1FeFE184f926ADf657Fa7ED3343027467bE8dCb2",
      "0x8DfD50F72223e8F5b5790918aaDfdBe81a04cd02",
      "0xd7bb6FF3e36FCaB73bB5dCF5e4E93A2125798980",
      "0xc8d3479BC79dF21773568c1bd4c1D95CB8d19028",
      "0xa064F10EF9dcCeD11C727F6Ab39bcBBF66E5B273",
      "0x2071b7ea350B5225004a84142c1E9eF4F1D1EE58",
      "0x8b7402a02626265aB6559B8B62748f42404d43D5",
      "0x82cABeBc738726c6835fFC379E794dA38A924924",
      "0xA82Fea35C9A457F37Caf7797A1a51441c461D155",
      "0x8B624C3e1B07B475b1b34ca483cA52C348612D43",
      "0x66aeF3cc300aC6BDD1A903d25290cF1B8eDB1155",
      "0x5C3351c9f5043984e4CecBF0EA858Ae782087607"
    ];
    const leafNodes = whitelist.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    setProof(merkleTree.getHexProof(keccak256(window.accounts[0])));

    setConnectionStatus(true);
  }

  let mintTokens = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (presaleLive) {
        if (!(await window.contract.methods.presaleLive().call())) {
          alert('Presale is not currently live!');
          setLoading(false);
          return;
        } else if (!(await window.contract.methods.isWhitelisted(window.accounts[0], proof).call())) {
          alert('Connected wallet is not whitelisted for the presale!');
          setLoading(false);
          return;
        }
      } else {
        if (!(await window.contract.methods.saleLive().call())) {
          alert('Sale is not currently live!');
          setLoading(false);
          return;
        }
      }
    } catch {
      alert('Please connect your Ethereum wallet!');
      setLoading(false);
      return;
    }

    var quantity = document.getElementById('tokenQuantity').value;
    try {
      var totalWei = window.web3.utils.toWei(String(total));
      if (presaleLive) {
        await window.contract.methods.presaleBuy(quantity, proof).send({from: window.accounts[0], value: totalWei})
        .on('receipt', function(receipt){
          if (receipt !== null) {
            alert('Minting was successful!')
          }
        });
      } else {
        await window.contract.methods.buy(quantity).send({from: window.accounts[0], value: totalWei});
      }
    } catch (err) {
      if (err.code === 'INVALID_ARGUMENT') {
        alert('Please select a quantity to mint!');
      } else if (err.code === 4001) {
        alert('Transaction cancelled by user!');
      } else if (err.code === -32603) {
        alert("Quantity exceeds wallet's available tokens!")
      } else {
        alert('An error occured!');
      }
      setLoading(false);
      return;
    }

    setTotal(0);
    setLoading(false);
    e.target.reset();
  }

  let handleInputChange = (e) => {
    setTotal(parseFloat(parseFloat(e.target.value * tokenPrice).toPrecision(12)));
  }

  if (connectionStatus) {
    // Subscribe to accounts change
    window.provider.on("accountsChanged", (accounts: string[]) => {
      connectWallet();
    });
  }

  return (
    <div className="App">

      <Container fluid>
        <img class="bg-image" alt="background" src="/background-planet.jpg"/>
        <div class="overlay"></div>
        <Row className="min-vh-100 main-bod">
          <Col>
            <div className="d-flex flex-column h-100">

              <Row>
                <Navbar expand="lg" bg="dark" variant="dark">
                  <Container className="mob-center">
                    <Navbar.Brand>
                      <img class="main-logo" alt="logo" src="/logo.png"/>
                    </Navbar.Brand>
                    <Button variant="primary" className="connect-button" onClick={ connectWallet }>{ account }</Button>
                  </Container>
                </Navbar>
              </Row>

              <Row className="justify-content-md-center align-items-center flex-grow-1 pt-5 main-body">
                <Col md="auto"/>
                <Col xs md={4}>
                  <h2 className="mint-title align-center">
                    Mint Your GYMBRO{presaleLive && ' '}
                    {presaleLive && (
                      <Badge className="bg-primary pb-2 mt-3 mt-md-sm-0 tag-btn">Presale</Badge>
                    )}
                  </h2>
                  <Card>
                    <Card.Body>

                      { presaleLive && connectionStatus ? [
                        <p className="mb-0">Total GYMBROs Minted</p>,
                        <h4>{ totalSupply }/2000</h4>,
                        <ProgressBar animated now={ (totalSupply / 2000) * 100 } label={`${ Math.round((totalSupply / 2000) * 100) }%`} />,
                        <hr className="my-3"/>
                      ] : connectionStatus ? [
                        <p className="mb-0">Total GYMBROs Minted</p>,
                        <h4>{ totalSupply }/10000</h4>,
                        <ProgressBar animated now={ (totalSupply / 10000) * 100 } label={`${ Math.round((totalSupply / 10000) * 100) }%`} />,
                        <hr className="my-3"/>
                      ] : null}

                      <Form onSubmit={ mintTokens }>
                        <p className="mb-0 text-end">Price Per GYMBRO</p>
                        <h4 className="text-end">{tokenPrice + ' '}
                          <b>ETH</b>
                        </h4>

                        <Form.Group className="mb-3">

                          {presaleLive ? (
                            <Form.Control
                              id="tokenQuantity"
                              type="number"
                              min="1"
                              max="3"
                              placeholder="Enter number of GYMBROs to mint*"
                              onChange={ handleInputChange }
                            />
                          ) : (
                            <Form.Control
                              id="tokenQuantity"
                              type="number"
                              min="1"
                              max="20"
                              placeholder="Enter number of GYMBROs to mint*"
                              onChange={ handleInputChange }
                            />
                          )}

                          <p className="text-end">
                            {presaleLive ? (
                              <small>*Max 3 GYMBROs Per Wallet</small>
                            ) : (
                              <small>*Max 20 Per Wallet</small>
                            )}
                          </p>
                        </Form.Group>

                        <hr className="my-3"/>
                        <div className="total-text">
                          <p className="m-0">Total</p>
                          <h5 className="m-0">{ total }{' '}
                            <b>ETH</b>
                          </h5>
                        </div>

                        <div className="d-grid gap-2">
                          <Button variant="primary" type="submit">
                            { !loading && "Mint" }
                          { loading && (
                            <Spinner
                              style={{
                                marginBottom: "0.1em",
                                marginLeft: "0.6em",
                                marginRight: "0.6em"
                              }}
                              as="span"
                              animation="border"
                              size="sm"
                              role="status" />
                          )}
                          </Button>
                        </div>
                      </Form>

                    </Card.Body>
                  </Card>
                </Col>
                <Col md="auto"/>
              </Row>

            </div>
          </Col>
          <Navbar className="pt-0 footer-nav">
            <span className="navbar-text mx-auto my-0 pt-0">
              View contract on
              {' '}
              <a href={'https://etherscan.io/address/' + contractAddress} target="blank" >
                <b>Etherscan</b>
              </a>
            </span>
          </Navbar>
        </Row>
      </Container>

    </div>
  );
}

export default App;